import React, { useEffect, useState } from 'react';
import PostForm from './PostForm';
import img from '../../../assets/images/auslaw-background.jpg';
import avatarimg from '../../../assets/images/logo-chat.png';
import { Tabs, Tab, Box, CircularProgress, useMediaQuery } from '@mui/material';
import { Colour } from '../../../styles/style';
import CommunityHeader from './CommunityHeader';
import AboutCommunity from './AboutCommunity';
import CommunityMembers from './CommunityMembers';
import PostList from './PostList';
import {
  useGetAllPostsByIdCommunityQuery,
  useGetAllUserAdmin,
  useGetAllUsersQuery,
  useGetCommunitiesByChatIdQuery,
} from '../../../api/queries';
import socket from '../../../api/socket/socket';
import { useCommunicatemutations } from '../../../api/mutations';
import { GET_ALL_COMMUNITIES, GET_CHAT_BY_CHATID } from '../../../constants/queryKeys';
import { queryClient } from '../../../api/queryClient';
import CommunityModal from '../modulCommunity/CommunityModal';
import AdminPanel from './AdminPanel';

interface Post {
  content: string;
  image: string | null;
  timestamp: string;
}

interface CommunityData {
  name: string;
  description: string;
  image: string | null;
  admins: string[];
  members: string[];
  imageFile: File | null;
}

interface PropsType {
  isPermissionAdmin?: boolean;
  hasInvite: boolean;
  acceptInvite: (id: number) => void;
  declineInvite: (id: number) => void;
}

function ContentCommunity({ isPermissionAdmin, hasInvite, acceptInvite, declineInvite }: PropsType) {
  const [tabIndex, setTabIndex] = useState<number>(1);
  const storedChatId = localStorage.getItem('idChat') || 0;
  const storedUserId = localStorage.getItem('userId');
  const [posts, setPosts] = useState<any[]>([]);
  const [postContent, setPostContent] = useState<string>('');
  const [image, setImage] = useState<string | null>(null);
  const [imageForm, setImageForm] = useState<string[]>();
  const [communityData, setCommunityData] = useState<CommunityData>({
    name: '',
    description: '',
    image: null,
    admins: [],
    members: [],
    imageFile: null,
  });
  const [selectedAdmins, setSelectedAdmins] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isJoin, setIsJoin] = useState<boolean>();

  const { data: communityChatById, isLoading } = useGetCommunitiesByChatIdQuery(+storedChatId);
  const { data: userList } = useGetAllUsersQuery();
  const { data: userAdminList } = useGetAllUserAdmin();
  const { data: postList = [] } = useGetAllPostsByIdCommunityQuery(communityChatById?.id);
  const { addUserCommunity, deleteUserCommunity, updateCommunity } = useCommunicatemutations();
  const isMobile = useMediaQuery('(max-width:990px)');

  const userId = localStorage.getItem('userId') || '0';
  const isCommunityMember = communityChatById?.members.includes(userId);

  console.log(isCommunityMember, 'isCommunityMember');

  const isAdmin = communityChatById?.admins.includes(userId) || false;
  const userIsJoin = storedUserId && communityChatById?.members.includes(storedUserId);

  const toggleModalEdit = () => {
    setIsEdit(!isEdit);
  };

  const targetIds = communityChatById?.admins;

  const filteredAdminUsers =
    userList &&
    userList
      .filter(user => targetIds && targetIds.includes(user.id.toString()))
      .map(user => user.username)
      .join(', ');

  const handlePostChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPostContent(event.target.value);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = function () {
        const result = reader.result;
        if (typeof result === 'string') {
          const base64String = result.split(',')[1];
          const mimeType = file.type;

          if (mimeType) {
            const prefixedBase64 = `data:${mimeType};base64,${base64String}`;
            setImageForm([prefixedBase64]);
          }
        }
      };

      reader.readAsDataURL(file);
      setImage(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (storedUserId && communityChatById) {
      const checkMemberCommunity = communityChatById?.members.includes(storedUserId);
      setIsJoin(checkMemberCommunity);
    }
  }, [communityChatById, storedUserId, isJoin]);

  const handleEditCommunity = () => {
    if (communityChatById) {
      const data = {
        name: communityData.name,
        description: communityData.description,
        members: selectedMembers,
        admins: selectedAdmins,
      };

      updateCommunity(
        { id: +communityChatById?.id, data },
        {
          onSuccess: () => {
            setIsEdit(!isEdit);
            queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, +storedChatId] });
            queryClient.invalidateQueries({ queryKey: [GET_ALL_COMMUNITIES] });
          },
          onError: err => {
            console.log(err);
          },
        },
      );
    }
  };

  const joinCommunity = () => {
    if (communityChatById && storedUserId && storedChatId) {
      const data = {
        userId: storedUserId,
      };
      addUserCommunity(
        { id: +communityChatById?.id, data },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, +storedChatId] });
          },
          onError: err => {
            console.log(err);
          },
        },
      );

      setIsJoin(true);
    }
  };

  const leaveCommunity = () => {
    if (communityChatById && storedUserId && storedChatId) {
      const deleteUserCommunityParams = {
        idCommunity: +communityChatById?.id,
        idUser: +storedUserId,
      };
      deleteUserCommunity(deleteUserCommunityParams, {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, +storedChatId] });
        },
        onError: err => {
          console.log(err);
        },
      });
      setIsJoin(false);
    }
  };

  const handlePostSubmit = () => {
    if ((postContent.trim() || image) && storedUserId) {
      const dataCreatePost = {
        creatorId: +storedUserId,
        communityId: communityChatById?.id,
        text: postContent,
        files: imageForm,
      };

      socket.emit('createPost', dataCreatePost);

      setPostContent('');
      setImage(null);
      setImageForm([]);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    socket.on('postCreated', newPost => {
      setPosts(prevPosts => {
        return [newPost, ...prevPosts];
      });
    });

    socket.on('postDeleted', deletedPost => {
      setPosts(prevPosts => prevPosts.filter(prevPost => prevPost.id !== deletedPost.postId));
    });

    socket.on('commentCreated', newComment => {
      console.log(newComment);
      setPosts(prevPosts =>
        prevPosts.map(post => {
          if (post.id === newComment.postId) {
            return {
              ...post,
              comments: [...(post.comments || []), newComment],
            };
          }
          return post;
        }),
      );
    });

    socket.on('commentDeleted', postDelete => {
      setPosts(prevPosts =>
        prevPosts.map(post => {
          if (post.id === postDelete.postId) {
            return {
              ...post,
              comments: post.comments.filter(comment => comment.id !== postDelete.id),
            };
          }
          return post;
        }),
      );
    });

    socket.on('commentEdited', editPost => {
      setPosts(prevPosts =>
        prevPosts.map(post => {
          if (post.id === editPost.postId) {
            return {
              ...post,
              comments: post.comments.map(comment =>
                comment.id === editPost.id ? { ...comment, text: editPost.text } : comment,
              ),
            };
          }
          return post;
        }),
      );
    });

    socket.on('postLiked', likePost => {
      setPosts(prevPosts =>
        prevPosts.map(post => {
          if (post.id === likePost.likedPost.id) {
            return {
              ...post,
              likes: [...post.likes, likePost.userId],
            };
          }
          return post;
        }),
      );
    });

    socket.on('postUnliked', unLikePost => {
      setPosts(prevPosts =>
        prevPosts.map(post => {
          if (post.id === unLikePost.postId) {
            return {
              ...post,
              likes: unLikePost.unlikedPost.files,
            };
          }
          return post;
        }),
      );
    });

    return () => {
      socket.off('createPost');
      socket.off('deletePost');
      socket.off('commentCreated');
      socket.off('commentDeleted');
      socket.off('commentEdited');
      socket.off('postLiked');
    };
  }, []);

  useEffect(() => {
    if (postList) {
      setPosts(postList);
    }

    if (!isAdmin) {
      setTabIndex(1);
    }
  }, [postList, isAdmin]);

  useEffect(() => {
    if (isEdit && communityChatById) {
      setCommunityData({
        ...communityData,
        name: communityChatById?.name,
        description: communityChatById?.description,
        admins: selectedAdmins,
        members: selectedMembers,
      });

      setSelectedMembers(communityChatById?.members);
      setSelectedAdmins(communityChatById?.admins);
    }
  }, [isEdit, communityChatById?.id]);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh',
        }}>
        <CircularProgress size={60} thickness={5} style={{ color: Colour.LightBlue }} />
      </Box>
    );
  }

  return (
    <div style={{ width: '100%', fontFamily: 'Barlow' }}>
      <CommunityHeader
        nameCommunity={communityChatById?.name}
        memberCommunity={communityChatById?.members}
        img={img}
        avatarimg={communityChatById?.image ?? avatarimg}
        Colour={Colour}
        joinCommunity={joinCommunity}
        leaveCommunity={leaveCommunity}
        isJoin={isJoin}
        toggleModalEdit={toggleModalEdit}
        isPermissionAdmin={isPermissionAdmin}
        hasInvite={hasInvite}
        acceptInvite={acceptInvite}
        declineInvite={declineInvite}
        isAdmin={isAdmin}
      />

      <div
        style={{
          width: '95%',
          borderBottom: '1px solid rgb(192, 192, 192)',
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 8,
          height: 20,
        }}></div>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: '20px',
        }}>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered={false}
          sx={{ marginBottom: '10px' }}
          variant="scrollable">
          <Tab label="About the group" sx={{ fontSize: isMobile ? '12px' : '14px' }} />
          <Tab label="Discussion" sx={{ fontSize: isMobile ? '12px' : '14px' }} />
          {(isAdmin || isPermissionAdmin) && <Tab label="Admin Panel" sx={{ fontSize: isMobile ? '12px' : '14px' }} />}
        </Tabs>
      </Box>

      {tabIndex === 0 && (
        <>
          <AboutCommunity
            Colour={Colour}
            description={communityChatById?.description}
            dataCreate={communityChatById?.createdAt}
            dataUpdate={communityChatById?.updatedAt}
          />
        </>
      )}

      {tabIndex === 1 && (
        <div>
          <PostForm
            postContent={postContent}
            handlePostChange={handlePostChange}
            handleImageUpload={handleImageUpload}
            handlePostSubmit={handlePostSubmit}
            isPermissionAdmin={isPermissionAdmin || false}
            image={image}
            userIsJoin={userIsJoin}
          />

          {!isCommunityMember && !isPermissionAdmin && !!posts.length ? (
            <div
              style={{
                textAlign: 'center',
                padding: '20px',
                color: 'black',
                borderRadius: '8px',
                marginTop: '20px',
                margin: '30px 20px',
              }}>
              <p>
                <strong>Join the community to view more content!</strong>
              </p>
            </div>
          ) : (
            <>
              <PostList posts={posts} isPermissionAdmin={isPermissionAdmin} isCommunityMember={isCommunityMember} />
            </>
          )}
        </div>
      )}

      {(isAdmin || isPermissionAdmin) && tabIndex === 2 && (
        <div style={{ overflowY: 'auto' }}>
          <AdminPanel communityData={communityChatById} />
        </div>
      )}

      <CommunityModal
        edit={true}
        open={isEdit}
        onClose={toggleModalEdit}
        communityData={communityData}
        setCommunityData={setCommunityData}
        adminsList={userAdminList}
        userList={userList}
        selectedAdmins={selectedAdmins}
        setSelectedAdmins={setSelectedAdmins}
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
        onSave={handleEditCommunity}
      />
    </div>
  );
}

export default ContentCommunity;
