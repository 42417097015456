import { useLinkTo } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { Controller, NestedValue, useForm } from 'react-hook-form';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton, SecondaryButton, TextButton } from '../../../components/Button';
import { selectedTimesHumanReadable } from '../../../components/Calendar/CalendarHelper';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Chip from '../../../components/Chip';
import ModalMultiSelect from '../../../components/ModalMultiSelect';
import Text from '../../../components/Text';
import { FilterType, SearchParams, setDateRange, setSearchParams } from '../../../redux/Search';
import { pickListsStateSelector, searchStateSelector, userStateSelector } from '../../../redux/Store';
import { Barlow, Colour, FontColour, FontSize } from '../../../styles/style';
import { ISearchForm } from '../utils/model';
import { auth, logAnalyticsEvent } from '../../../services/Firebase';
import moment from 'moment';
import { useAuthmutations } from '../../../api/mutations';
import { useQueryClient } from '@tanstack/react-query';
import socket from '../../../api/socket/socket';

const defaultSearchForm: ISearchForm = {
  services: [] as unknown as NestedValue<string[]>,
  locations: [] as unknown as NestedValue<string[]>,
  dates: [],
};

const descriptionTextStyle = [Barlow.regular, FontSize.large, FontColour.darkBlue, { paddingTop: 10 }];
const subDescTextStyle = [Barlow.regular, FontSize.normal, FontColour.grey];

interface Props {
  setIsMainSearch(value: boolean): void;
}

const SearchParamForm = (props: Props) => {
  const { setIsMainSearch } = props;
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const pickLists = useSelector(pickListsStateSelector);
  const { dates, params } = useSelector(searchStateSelector);

  const { loginMutate, registerMutate } = useAuthmutations();

  const { control, setValue, reset, getValues, watch } = useForm<ISearchForm>({
    defaultValues: {
      ...defaultSearchForm,
      dates,
    },
  });

  const userState = useSelector(userStateSelector);

  useEffect(() => {
    const dataUser = {
      username: userState.profile?.name,
      password: userState.auth?.userId,
    };

    loginMutate(dataUser, {
      onSuccess: data => {
        // Save user ID to local storage
        localStorage.setItem('userId', data.data.user.id);

        socket.emit('sendMessage', data);
      },
      onError: err => {
        registerMutate(dataUser, {
          onSuccess: data => {
            // Save user ID to local storage
            localStorage.setItem('userId', data.data.user.id);

            socket.emit('sendMessage', data);
          },
          onError: err => {
            console.log('Registration error:', err);
          },
        });
      },
    });
  }, []);

  React.useEffect(() => {
    setValue('dates', dates);
  }, [dates, setValue]);

  const valueLooking = watch('services');
  const valueLocations = watch('locations');

  useEffect(() => {
    const startDate = dates && dates[0];
    const endDate = dates && dates.length > 1 ? dates[1] : moment(startDate).add(4, 'hour').toISOString();

    const newParams: SearchParams = {
      filters: [
        ...(valueLooking.length
          ? [
              {
                type: FilterType.Service,
                value: valueLooking,
              },
            ]
          : []),
        ...(valueLocations.length
          ? [
              {
                type: FilterType.Service,
                value: valueLocations,
              },
            ]
          : []),
        ...(dates?.length
          ? [
              {
                type: FilterType.DateRange,
                value: { startDate, endDate },
              },
            ]
          : []),
      ],
    };

    dispatch(setSearchParams(newParams));
  }, [valueLooking, valueLocations, dates]);

  return (
    <View style={{ flex: 1, paddingHorizontal: 20 }}>
      <View style={{ flex: 1, marginBottom: 40 }}>
        <Text style={descriptionTextStyle}>I am looking for</Text>
        <Controller
          control={control}
          name="services"
          render={({ field: { onChange, value } }) => (
            <ModalMultiSelect
              value={value}
              onChange={onChange}
              onModalOpen={() => {
                logAnalyticsEvent('specialisation_filter_opened', {});
              }}
              onSelect={selected => {
                logAnalyticsEvent('specialisation_selected', {
                  source: 'filter',
                  selected,
                });
              }}
              items={pickLists.services}
              selectText="Add Service / Specialisation"
              headerText="Services / Specialisations"
            />
          )}
        />
        <View style={{ flex: 1, maxHeight: 20, minHeight: 0 }} />

        <Text style={descriptionTextStyle}>In this location</Text>
        <Controller
          control={control}
          name="locations"
          render={({ field: { onChange, value } }) => (
            <ModalMultiSelect
              value={value}
              onChange={onChange}
              onModalOpen={() => {
                logAnalyticsEvent('location_filter_opened', {});
              }}
              onSelect={selected => {
                logAnalyticsEvent('location_selected', {
                  source: 'filter',
                  selected,
                });
              }}
              items={pickLists.locations}
              selectText="Add location"
              headerText="Locations"
            />
          )}
        />
        <View style={{ flex: 1, maxHeight: 20, minHeight: 0 }} />

        <Text style={descriptionTextStyle}>Who is available</Text>
        <Text style={subDescTextStyle}>On one/some of these days/times</Text>
        <SecondaryButton
          title="Select Dates"
          iconRight
          icon={<DateRangeIcon sx={{ color: Colour.DarkBlue }} />}
          onPress={() => {
            logAnalyticsEvent('date_filter_opened', {});
            linkTo('/calendar');
          }}
        />
        {dates && (
          <Chip onDelete={() => dispatch(setDateRange(undefined))}>
            <Text>{selectedTimesHumanReadable(dates, 4)}</Text>
          </Chip>
        )}
        <TextButton
          onPress={() => {
            reset({ ...defaultSearchForm, dates: undefined });
            dispatch(setDateRange(undefined));
          }}
          text="Reset"
          textStyle={{ color: Colour.Blue }}
        />
        <PrimaryButton
          title="SEARCH"
          onPress={() => {
            logAnalyticsEvent('search_clicked', {
              locationFilters: getValues('locations'),
              specialisationFilters: getValues('services'),
              dateFilters: getValues('dates') ?? [],
            });
            setIsMainSearch(false);
          }}
        />
      </View>
    </View>
  );
};

export default SearchParamForm;
