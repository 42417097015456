import moment from 'moment';
import { Asset, CameraOptions, ImageLibraryOptions } from 'react-native-image-picker';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import config from '../config.json';
import {
  getFirestore,
  collection,
  getDocs,
  setDoc,
  doc,
  addDoc,
  getDoc,
  where,
  query,
  deleteDoc,
  CollectionReference,
  Query,
  updateDoc,
  deleteField,
  DocumentReference,
} from 'firebase/firestore/lite';
import { onSnapshot } from 'firebase/firestore';
import { getStorage, uploadBytes, ref, getDownloadURL, StorageReference } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
} from 'firebase/auth';
import { CalendarEvent } from '../redux/Calendar';
import { RegisterModel, UserType } from '../redux/Register';
import { Profile, SignInModel } from '../redux/User';
import { captureException } from '@sentry/react';

export enum Collections {
  // User
  Profiles = 'profiles',

  Favorites = 'favorites',

  // Calendar
  SelectedCalendars = 'selectedWebCalendars',
  MobileCalendars = 'selectedCalendars',

  CalendarEvents = 'calendarEvents',

  // Listings
  Listings = 'listings',

  // Static Lists
  Services = 'services',
  Specialisations = 'specialisations',
  Locations = 'locations',
  Prices = 'prices',

  // Services, specialisations, and locations requested by users
  ServiceRequests = 'serviceRequests',

  // Handle invitations
  Invitations = 'invitations',

  // Sending mail
  MailToSend = 'mailToSend',

  // Stripe
  Pricing = 'pricing',
}

export enum AuthErrorCode {
  InvalidEmail = 'auth/invalid-email',
  AlreadyInUse = 'auth/email-already-in-use',
  NoUserFound = 'auth/user-not-found',
  WrongPassword = 'auth/wrong-password',
}

//app
const app = initializeApp({
  //todo: add firebase config
  appId: config.firebaseAppId,
  projectId: config.firebaseProjectId,
  apiKey: config.firebaseApiKey,
});

// - - - Functions - - -
const functions = getFunctions(app, 'australia-southeast1');
// const functions = app.functions('australia-southeast1');
// functions.useFunctionsEmulator('http://localhost:5001');

export type Functions =
  | 'search'
  | 'payWithStripe'
  | 'sendResetPassword'
  | 'cancelSubscription'
  | 'updateListingManual'
  | 'getNylasAuthLink'
  | 'exchangeMailboxToken'
  | 'getCalendars'
  | 'syncCalendar'
  | 'requestAuthorisationToMergeListing'
  | 'mergeListing'
  | 'cancelNylasAccount';

export const CallFunction = (func: Functions, params: any) =>
  new Promise((res, rej) => {
    httpsCallable(functions, func, { timeout: 100000 })(params)
      .then(result => res(result.data))
      .catch(rej);
  });

// - - - Firestore - - -

export const firestore = getFirestore();

export async function getFirestoreDataAll(collectionName: string) {
  try {
    const collectionRef = collection(firestore, collectionName);
    const querySnapshot = await getDocs(collectionRef);

    const allData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    return allData;
  } catch (error) {
    console.error('Error fetching documents: ', error);
    return null;
  }
}

export async function getFirestoreData(collection: string, id: string) {
  try {
    const docRef = doc(firestore, collection, id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching document: ', error);
    return null;
  }
}

export async function setDocumentFirestore(collectionName: string, documentId: string, data: any) {
  const docRef = doc(firestore, collectionName, documentId);
  await setDoc(docRef, data, { merge: true });
}

export async function deleteDocumentFirestore(collectionName: string, documentId: string) {
  const docRef = doc(firestore, collectionName, documentId);
  await deleteDoc(docRef);
}

// Base collection helper
export const Collection = (collec: Collections) => collection(firestore, collec);

export const Document = (collection: Collections, docId?: string) => {
  let path = `${collection}`;
  if (docId) {
    path = `${collection}/${docId}`;
  }
  return doc(firestore, path);
};

// Service requests
export const AddServiceRequest = (
  userId: string,
  services: string,
  specialisations: string,
  locations: string,
  furtherInformation: string,
) => {
  return addDoc(Collection(Collections.ServiceRequests), {
    userId,
    services,
    specialisations,
    locations,
    furtherInformation,
  });
};

export const imagePickerOptions: ImageLibraryOptions = {
  maxWidth: 512,
  maxHeight: 512,
  quality: 1,
  mediaType: 'photo',
};

export const imageDoylesPickerOptions: ImageLibraryOptions = {
  quality: 1,
  mediaType: 'photo',
};

export const takePhotoOptions: CameraOptions = {
  saveToPhotos: true,
  cameraType: 'back',
  mediaType: 'photo',
};

// Image storage helper

const storage = getStorage(app, config.defaultBucket);
export const SaveImage = (id: string, imageData: Asset, isDoylesImage?: boolean) =>
  new Promise<string>((resolve, reject) => {
    // Save to firebase storage
    let reference: StorageReference;
    if (isDoylesImage) {
      reference = ref(storage, `doyles_image/${id}`);
    } else {
      reference = ref(storage, `profile_image/${id}`);
    }
    if (!imageData.base64 && !imageData.uri) {
      const err = Error('Missing image base 64 or uri');
      captureException(err);
      throw err;
    }
    fetch(imageData.uri ?? imageData?.base64)
      .then(res => res.blob())
      .then(blob => {
        uploadBytes(reference, blob)
          .then(res => {
            // Return the full storage url
            getDownloadURL(reference).then(url => resolve(url));
          })
          .catch(error => {
            captureException(error);
            reject(undefined);
            alert('Image save error');
          });
      });
  });

export type Message = {
  recipient: string;
  sender: string;
  message: string;
  contact: string;
  from: string;
  to: string;
  listing: string;
};

export const SendMessageToUser = (to: string, cc: string[], data: Message) => {
  return addDoc(Collection(Collections.MailToSend), {
    to,
    cc,
    template: {
      name: 'Message',
      data,
    },
  });
};

// Listings for user
export const ListingCollection = Collection(Collections.Listings);
export const ListingDocument = (docId: string) => {
  return doc(firestore, `${Collections.Listings}/${docId}`);
};
export const ListingsForUser = (userId: string) => {
  const listingsRef = collection(firestore, Collections.Listings);
  const q = query(listingsRef, where('profileId', '==', userId));
  return getDocs(q);
};

export const ListingsForUserEmail = (email: string) => {
  const listingsRef = collection(firestore, Collections.Listings);
  const q = query(listingsRef, where('profileEmail', '==', email));
  return getDocs(q);
};

export const EventsForUser = (userId: string, resolve: (events: CalendarEvent[]) => void) => {
  const listingsRef = collection(firestore, Collections.Listings);
  const q = query(listingsRef, where('profileId', '==', userId));
  return getDocs(q).then(snapshot => {
    snapshot.forEach(doc => {
      if (!snapshot.empty && snapshot.docs.length > 0) {
        const listingEvents = snapshot.docs[0].data().events as CalendarEvent[];
        resolve(listingEvents);
      }
    });
  });
};

export const SaveProfile = async (id: string, name: string, email: string, phone: string): Promise<void> => {
  let data = {
    name,
    email,
    phone,
  } as any;
  const profileRef = doc(firestore, Collections.Profiles, id);
  await setDoc(profileRef, data, { merge: true });
};

// Users and Orgs
export const ProfileCollection = Collection(Collections.Profiles);
export const ProfileDocument = (docId: string) => doc(firestore, `${Collections.Profiles}/${docId}`);
export const GetProfileByEmail = (email: string) => {
  const profilesRef = collection(firestore, Collections.Profiles);
  const q = query(profilesRef, where('email', '==', email));
  return getDocs(q);
};
export const GetProfile = (profileId: string) =>
  new Promise<Profile>(resolve => {
    // Set up document connections
    getDoc(ProfileDocument(profileId))
      .then(result => {
        resolve(result.data() as Profile);
      })
      .catch(error => {
        alert('Error getting profile');
        captureException(error);
      });
  });

// Favorites
export const UserFavorites = () => doc(firestore, `${Collections.Favorites}/${auth.currentUser?.uid}`);

export const GetUserFavorites = () =>
  new Promise<string[]>(resolve => {
    getDoc(UserFavorites())
      .then(snapshot => {
        if (snapshot.data()) {
          resolve(snapshot.data()!.listingIds as string[]);
        }
        resolve([]);
      })
      .catch(error => {
        alert('Error getting user favourites');
        captureException(error);
      });
  });

export const SetUserFavorites = (listingIds: string[]) => {
  return setDoc(UserFavorites(), {
    listingIds,
  });
};

// Firebase Auth

export const auth = getAuth(app);

export const GetAuthErrorMessage = (code: AuthErrorCode) => {
  switch (code) {
    case AuthErrorCode.AlreadyInUse:
      return 'This email is already in use';
    case AuthErrorCode.NoUserFound:
    case AuthErrorCode.WrongPassword:
      return 'Email or password is incorrect';
    case AuthErrorCode.InvalidEmail:
      return 'That is not a valid email address';
    default:
      return 'Something went wrong';
  }
};

export const SignIn = (data: SignInModel) => {
  return signInWithEmailAndPassword(auth, data.email, data.password).then(res => {
    logAnalyticsEvent('login', {
      source: 'webapp',
    });
    return res;
  });
};

export const SignOut = () => signOut(auth);

export const DeleteAccount = () => {};

// Custom reset password function instead of using firebase's built-in auth method, because we couldn't change the firebase email template's message body at the time. If you're seeing this for the first time, check if this has changed?
export const ResetPassword = (email: string) => CallFunction('sendResetPassword', email);

export const Register = (data: RegisterModel) => createUserWithEmailAndPassword(auth, data.email, data.password);
export const UpdateProfile = (name?: string, photoUrl?: string) => {
  if (!auth.currentUser) {
    throw new Error(AuthErrorCode.NoUserFound);
  }
  return updateProfile(auth.currentUser, {
    displayName: name,
    photoURL: photoUrl,
  });
};

export const CancelSubscription = (subscriptionId: string) => CallFunction('cancelSubscription', { subscriptionId });

export const DeleteUser = async (subscriptionId: string) => {
  const uid = auth.currentUser?.uid;
  if (!uid || !auth.currentUser) {
    throw new Error(AuthErrorCode.NoUserFound);
  }

  if (subscriptionId) await CancelSubscription(subscriptionId);
  deleteDoc(ProfileDocument(uid));
  deleteUser(auth.currentUser);
};

//Analtics
const analytics = getAnalytics(app);

export interface AppEvents {
  login: {
    source: 'webapp'; //Any logins from here are defs from the webapp! Other places should (in future) specify mobile or web portal
  };
  screen_view: {
    firebase_screen: string;
  };
  specialisation_filter_opened: {};
  specialisation_selected: {
    source: 'filter' | 'editListing';
    selected: string[];
  };
  location_filter_opened: {};
  location_selected: {
    source: 'filter' | 'editListing';
    selected: string[];
  };
  date_filter_opened: {};
  date_selected: {
    source: 'filter' | 'editListing';
    selected: string[];
  };
  invite_sent: {
    orgId: string;
  };
  invite_cancelled: {
    orgId: string;
  };
  invite_accepted: {
    orgId: string;
    uid: string;
  };
  search_clicked: {
    locationFilters: string[];
    specialisationFilters: string[];
    dateFilters: string[];
  };
  text_search_performed: {
    text?: string;
  };
  favorite_toggled: {
    favoritedId: string;
    isFavorite: boolean;
  };
  account_type_selected: {
    type: UserType;
  };
  listing_updated: {
    new: boolean;
    hasImage: boolean;
    hasName: boolean;
    hasHourlyRate: boolean;
    hasDailyRate: boolean;
    hasReportCost: boolean;
    hasBio: boolean;
    hasPhone: boolean;
    hasMob: boolean;
    hasEmail: boolean;
    hasAddress: boolean;
    hasSuburb: boolean;
    hasPostcode: boolean;
    hasState: boolean;
    hasServices: boolean;
    hasLocations: boolean;
    hasAccreditations: boolean;
    hasCalendar: boolean;
    hasEvents: boolean;
    type: UserType;
    isDoyles: boolean;
    hasDoylesImages: boolean;
  };
  listing_viewed: {
    listingId?: string;
  };
  calendar_connected: {};
  payment_attempted: {
    success: boolean;
  };
  message_sent: {
    sentTo: string;
  };
  call_pressed: {
    listingId: string;
  };
  calendar_connection_skipped: {};
  calendar_dates_selected: {
    from: string;
    to?: string;
  };
  calendar_viewed: {
    listingId: string;
  };
  calendar_synced_with_no_events: {
    listingId: string;
    calendarId: string;
  };
}

//Wrappper to help us define payloads
export const logAnalyticsEvent = <T extends keyof AppEvents>(eventName: T, eventParams: AppEvents[T]) => {
  return logEvent<string>(analytics, eventName, {
    ...eventParams,
    uid: auth.currentUser?.uid,
  });
};

export const logScreenView = (screenName: string) => {
  logAnalyticsEvent('screen_view', { firebase_screen: screenName });
};

export interface IFirebaseBaseDocument {
  id?: string;
  dateCreated?: string;
  dateModified?: string;
}

export const CollectionSnapshot = <T extends IFirebaseBaseDocument>(collection: Collections) =>
  new Promise<T[]>((resolve, reject) => {
    getDocs(Collection(collection))
      .then(snapshot => {
        if (snapshot && !snapshot.empty) {
          let data = snapshot.docs.map(doc => {
            const data = doc.data();
            if (data.id == null) {
              data.id = doc.id;
            }
            return data as T;
          });
          resolve(data);
        }
      })
      .catch(rejected => reject(new Error(`Error retrieving collection snapshot: ${collection}\n${rejected}`)));
  });

export const DocumentSnapshot = <T extends IFirebaseBaseDocument>(collection: Collections, docId: string) =>
  new Promise<T>((resolve, reject) => {
    getDoc(Document(collection, docId))
      .then(snapshot => {
        if (snapshot) {
          let data = snapshot.data() as T;
          if (data.id == null) {
            data.id = snapshot.id;
          }
          resolve(data);
        }
      })
      .catch(rejected => reject(new Error(`Error retrieving document snapshot: ${collection}\n${rejected}`)));
  });

// Subscription Snapshots
export const SubscribeToCollection = <T extends IFirebaseBaseDocument>(
  collectionRef: CollectionReference | Query,
  onSnapshotCB: (collectionSnapshot: T[]) => void,
  onError: (error: Error) => void,
) =>
  onSnapshot(
    collectionRef,
    snapshot => {
      if (snapshot) {
        const data = snapshot.docs.map(doc => {
          const data = doc.data();
          if (data.id == null) {
            data.id = doc.id;
          }
          return data as T;
        });
        onSnapshotCB(data);
      }
    },
    onError,
  );

// export const SubscribeDocument = <T extends unknown>(
//     documentRef: FirebaseFirestoreTypes.DocumentReference<FirebaseFirestoreTypes.DocumentData>)
export const SubscribeDocument = <T extends IFirebaseBaseDocument>(
  documentRef: DocumentReference,
  onSnapshotCB: (documentSnapshot: T) => void,
  onError: (error: Error) => void,
) =>
  onSnapshot(
    documentRef,
    snapshot => {
      if (snapshot) {
        const data = snapshot.data() as T;
        onSnapshotCB(data);
      }
    },
    onError,
  );

// Set helpers

export const SetDocument = <T extends IFirebaseBaseDocument>(collection: Collections, data: T) => {
  // Get the document reference
  const document = Document(collection, data.id);

  // Set id if it doesn't exist
  if (data.id == null) {
    data.id = document.id;
  }

  // Set dates
  if (data.dateCreated === undefined) {
    data.dateCreated = moment().toISOString();
  }
  data.dateModified = moment().toISOString();

  // Set the document data
  setDoc(document, { ...data }, { merge: true });
};

export const CreateFirestoreHelper = <T extends IFirebaseBaseDocument>(collection: Collections) => ({
  /**
   * Get all documents from collection
   */
  GetCollection: () => CollectionSnapshot<T>(collection),

  /**
   * Get specific document from collection
   * @param id Document id
   */
  GetDocument: (id: string) => DocumentSnapshot<T>(collection, id),

  /**
   * Sets a document's data, and merges with existing data by default.
   * Use the overwrite parameter to turn off merging.
   * @param data The data to set
   * @param overwrite Should this overwrite existing data?
   */
  SetDocument: (data: T) => SetDocument(collection, data),

  /**
   * Update's a document's data, and merges with existing data.
   *
   * A looser version of SetDocument that bypasses T to avoid needing to set required properties (if any).
   * @param data A
   */
  UpdateDocument: (data: any) => SetDocument(collection, data),

  /**
   * Deletes a document and returns a completion promise
   * @param id The document id
   */
  DeleteDocument: (id: string) => deleteDoc(Document(collection, id)),

  /**
   * Deletes a field from a document
   * @param id The id of the document
   * @param fieldName The name of the field to delete
   */
  DeleteField: (id: string, fieldName: string) =>
    updateDoc(Document(collection, id), {
      [fieldName]: deleteField(),
    }),

  /**
   * Attaches a listener for collection events.
   *
   * Returns an unsubscribe function to stop listening to events.
   * @param onSnapshot event callback
   * @param onError error callback (optional)
   */
  SubscribeToCollection: (onSnapshot: (document: T[]) => void, onError?: (error: Error) => void) =>
    SubscribeToCollection(Collection(collection), onSnapshot, onError ?? console.log),

  /**
   * Attaches a listener for document events.
   *
   * Returns an unsubscribe function to stop listening to events.
   * @param id Document id
   * @param onSnapshot event callback
   * @param onError error callback (optional)
   */
  SubscribeToDocument: (id: string, onSnapshot: (document: T) => void, onError?: (error: Error) => void) =>
    SubscribeDocument<T>(Document(collection, id), onSnapshot, onError ?? console.log),
});
