import { useLinkTo, useNavigation, StackActions } from '@react-navigation/native';
import * as Sentry from '@sentry/browser';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { Image } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import logo from '../assets/images/imgLogoIcon34px@3x.png';
import logoWithText from '../assets/images/Auslaw-Logo-Inline-Reverse.png';
import { AllStackParamList } from '../navigators/Types';
import { fetchSelectedCalendar } from '../redux/Calendar';
import { resetInvitationId, setInvitationId } from '../redux/Invites';
import { fetchLocations, fetchServices } from '../redux/PickLists';
import { UserType } from '../redux/Register';
import { calendarStateSelector, inviteSelector, userStateSelector } from '../redux/Store';
import { setProducts } from '../redux/Stripe';
import MenuIcon from '@mui/icons-material/Menu';
import {
  fetchOrgInvites,
  fetchOrgListings,
  Invite,
  Listing,
  reset,
  setAuth,
  setFavorites,
  setListing,
  setLoadingState,
  setProfile,
} from '../redux/User';
import MenuScreen from '../screens/MenuScreen';
import AcceptInvitation from '../screens/AcceptInvitation';
import AccountTypeScreen from '../screens/auth/AccountTypeScreen';
import ForgotScreen from '../screens/auth/ForgotPasswordScreen';
import LoginScreen from '../screens/auth/LoginScreen';
import PasswordSentScreen from '../screens/auth/PasswordSentScreen';
import SignupCompleteScreen from '../screens/auth/SignupCompleteScreen';
import SignupScreen from '../screens/auth/SignupScreen';
import WelcomeScreen from '../screens/auth/WelcomeScreen';
import CalendarScreen from '../screens/calendar/CalendarScreen';
import ChooseCalendarScreen from '../screens/calendar/ChooseCalendarScreen';
import HelpScreen from '../screens/HelpScreen';
import CreateListingScreen from '../screens/listing/CreateListingScreen';
import ViewListing from '../screens/listing/ViewListing';
import LoadingScreen from '../screens/LoadingScreen';
import MessageSent from '../screens/message/MessageSent';
import SendMessage from '../screens/message/SendMessage';
import EditOrganisation from '../screens/org/EditOrganisation';
import ViewOrganisation from '../screens/org/ViewOrganisation';
import EditPersonalProfile from '../screens/profile/EditPersonalProfile';
import ViewPersonalProfile from '../screens/profile/ViewPersonalProfile';
import RefineSearchScreen from '../screens/search/RefineSearchScreen';
import {
  GetProfile,
  GetUserFavorites,
  ListingsForUser,
  ListingsForUserEmail,
  SignOut,
  auth,
} from '../services/Firebase';
import { GetInvitation } from '../services/InvitationService';
import { StripeProductFirestore } from '../services/StripeService';
import { Colour } from '../styles/style';
import SearchNavigator from './SearchNavigator';
import PaymentScreen from '../screens/payment/PaymentScreen';
import ProfileListScreen from '../screens/developer/ProfileListScreen';
import ConfirmAccountDeletionScreen from '../screens/profile/ConfirmAccountDeletionScreen';
import AccountRemovedScreen from '../screens/profile/AccountRemovedScreen';
import { User, onAuthStateChanged } from 'firebase/auth';
import { MenuContext } from '../../App';
import PasswordResetScreen from '../screens/auth/PasswordResetScreen';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import VersionScreen from '../screens/version';
import { captureException } from '@sentry/react';
import MergeListing from '../screens/MergeListing';
import ChatMainBlock from '../screens/chat/ChatMainBlock';
import ChatReviewComponent from '../screens/chat/ChatReviewComponent';
import CommunityMainBlock from '../screens/community/CommunityMainBlock';
import ChooseCommunity from '../screens/community/ChooseCommunity';

const Stack = createStackNavigator<AllStackParamList>();

const customHeader: StackNavigationOptions = {
  headerTransparent: true,
  headerTintColor: 'white',
  headerTitleAlign: 'center',
  headerTitle: () => <Image style={{ width: 32, height: 32 }} source={logo} on />,
};

const noShadowHeader: StackNavigationOptions = {
  headerTitle: '',
  cardStyle: {
    shadowColor: 'transparent',
    elevation: 0,
  },
  headerStyle: {
    elevation: 0,
    shadowColor: 'transparent',
    backgroundColor: Colour.Blue,
  },
};
const noShadowNoBorderHeader: StackNavigationOptions = {
  headerTitle: '',
  cardStyle: {
    shadowColor: 'transparent',
    elevation: 0,
  },
  headerStyle: {
    borderWidth: 0,
    elevation: 0,
    shadowColor: 'transparent',
    backgroundColor: 'rgb(11 117 178)',
  },
};

const AppNavigator = () => {
  const linkTo = useLinkTo();
  const nav = useNavigation();
  const dispatch = useDispatch();
  const inviteState = useSelector(inviteSelector);
  const calendarState = useSelector(calendarStateSelector);
  const userState = useSelector(userStateSelector);
  const [openCalendarToast, setOpenCalendarToast] = useState(false);
  const [calendarToastDismissed, setCalendarToastDismissed] = useState(false);
  const [menuOpen, setMenuOpen] = useContext(MenuContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [syncTimer, setSyncTimer] = useState<NodeJS.Timeout>();

  useEffect(() => {
    const state = nav.getState();
    const invitationId = state?.routes[0]?.params?.inviteId;
    if (invitationId !== undefined) {
      // ignore any proven bad invitations (ie cancelled), else we can end up in a loop
      if (inviteState.badInvitations.findIndex(x => x == invitationId) === -1) {
        dispatch(setInvitationId(invitationId));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    if (inviteState.invitationId !== undefined && inviteState.invitationId.length > 0) {
      setLoading(true);
      // Check if invitation is still valid before navigating
      GetInvitation(inviteState.invitationId)
        .then(snapshot => {
          setLoading(false);

          if (snapshot === undefined) {
            alert('Invalid invitation');
            captureException(
              `Invalid invitation: could not find invite with inivitationId ${inviteState.invitationId}`,
            );
            return;
          }
          const invite = snapshot as Invite;

          if (invite.cancelled || invite.accepted) {
            alert('Invitation is no longer valid');
            return;
          }

          linkTo({
            screen: 'AcceptInvitation',
            params: { ...invite },
          });
          dispatch(resetInvitationId());
        })
        .catch(error => {
          alert('Error getting invitation');
          captureException(error);
          setLoading(false);
        });
    }
  }, [dispatch, inviteState.invitationId]);

  React.useEffect(() => {
    if (
      calendarState.selectedCalendar &&
      !calendarState.selectedCalendar.connected &&
      !userState.listing?.nylasConnected &&
      !calendarToastDismissed &&
      !openCalendarToast
    ) {
      setOpenCalendarToast(true);
    } else {
      setOpenCalendarToast(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarState.selectedCalendar]);

  const onToastClose = () => {
    setCalendarToastDismissed(true);
    setOpenCalendarToast(false);
  };

  // Firebase auth tracks our authentication state, and automatically persists between app sessions
  const debouncedAuthStateChanged = useDebouncedCallback((user: User | null) => {
    // dispatch new auth
    if (user !== null) {
      dispatch(
        setAuth({
          email: user.email!,
          userId: user.uid,
        }),
      );
      Sentry.setUser({
        id: user.uid,
      });
      dispatch(setLoadingState(true));
      // Wait to load before navigating to search screen
      Promise.all([
        // Fetch user profile
        GetProfile(user.uid).then(profile => {
          if (!profile) {
            return;
          }

          dispatch(setProfile(profile));
          if (profile.userType === UserType.Individual) {
            // Other data that we want to load in the background
            dispatch(fetchSelectedCalendar());
          } else {
            // Also get listings and invites if user is an org
            dispatch(fetchOrgListings());
            dispatch(fetchOrgInvites());
          }
        }),
        GetUserFavorites().then(userIds => {
          dispatch(setFavorites(userIds));
        }),
        ListingsForUser(user.uid).then(result => {
          if (result.docs.length > 0) {
            let listing = result.docs[0].data() as Listing;
            listing.id = result.docs[0].id;
            dispatch(setListing(listing));
          }
        }),
        (function () {
          if (user.email) {
            return ListingsForUserEmail(user.email).then(result => {
              if (result.docs.length > 0) {
                let listing = result.docs[0].data() as Listing;
                listing.id = result.docs[0].id;
                dispatch(setListing(listing));
              }
            });
          } else {
            return Promise.resolve();
          }
        })(),
      ])
        .then(() => {
          dispatch(setLoadingState(false));
          const currentRouteName = nav.getCurrentRoute()?.name;
          if (currentRouteName === 'Loading' || currentRouteName === 'Login' || currentRouteName === 'Welcome') {
            const options = nav?.getCurrentRoute()?.params;
            if (options === undefined || !options.hasOwnProperty('returnToInvite')) {
              linkTo('/search');
            }
          }
        })
        .catch(err => {
          dispatch(setLoadingState(false));
          captureException(err);
          SignOut();
        });
    } else {
      const currentRouteName = nav?.getCurrentRoute()?.name;

      if (currentRouteName !== 'AcceptInvitation' && currentRouteName !== 'AccountRemovedScreen') {
        dispatch(reset);
        linkTo('/welcome');
      }

      if (syncTimer) {
        clearInterval(syncTimer);
      }
    }
  }, 150);

  useEffect(() => {
    // Subscribe to authenticator listener
    const unsubAuth = onAuthStateChanged(auth, debouncedAuthStateChanged);

    // Set/Update picklists
    dispatch(fetchServices());
    dispatch(fetchLocations());

    StripeProductFirestore.GetCollection()
      .then(products => {
        dispatch(setProducts(products));
      })
      .catch(err => {
        captureException(err);
      });

    // return unsubscribe function
    return () => {
      unsubAuth();

      if (syncTimer) {
        clearInterval(syncTimer);
      }
    };
  }, []);

  return (
    <>
      <Stack.Navigator
        initialRouteName="Loading"
        screenOptions={{
          headerStyle: {
            backgroundColor: 'rgb(11 117 178)',
          },
          headerTintColor: 'white',
          headerTitleStyle: {
            fontFamily: 'Barlow',
            letterSpacing: 1,
            fontWeight: '500',
            fontSize: 20,
          },
          headerBackTitleVisible: false,
          headerLeft: () => (
            <View style={{ marginLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <MenuIcon
                sx={{ color: 'white', marginRight: '10px', cursor: 'pointer' }}
                onClick={() => linkTo('/menu')}
              />
              <View onClick={() => linkTo('/search')} style={{ cursor: 'pointer' }}>
                <Image style={{ width: 200, height: 26 }} source={logoWithText} />
              </View>
            </View>
          ),
        }}>
        <Stack.Screen
          name="Loading"
          component={LoadingScreen}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Menu"
          component={MenuScreen}
          options={{
            headerShown: false,
          }}
        />

        <Stack.Screen
          name="Version"
          component={VersionScreen}
          options={{
            headerShown: false,
          }}
        />
        <Stack.Screen name="Welcome" component={WelcomeScreen} options={{ headerShown: false }} />

        <Stack.Screen name="ProfileList" component={ProfileListScreen} options={{}} />

        <Stack.Screen name="SearchNavigator" component={SearchNavigator} options={noShadowNoBorderHeader} />

        <Stack.Screen name="RefineSearch" component={RefineSearchScreen} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ChooseCalendar" component={ChooseCalendarScreen} options={noShadowNoBorderHeader} />

        <Stack.Screen name="Calendar" component={CalendarScreen} options={noShadowNoBorderHeader} />

        <Stack.Screen name="CreateListing" component={CreateListingScreen} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ViewListing" component={ViewListing} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ViewPersonalProfile" component={ViewPersonalProfile} options={noShadowNoBorderHeader} />

        <Stack.Screen
          //@ts-ignore
          name="ConfirmAccountDeletionScreen"
          component={ConfirmAccountDeletionScreen}
          options={noShadowNoBorderHeader}
        />
        <Stack.Screen name="EditPersonalProfile" component={EditPersonalProfile} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ChooseCommunity" component={ChooseCommunity} options={noShadowNoBorderHeader} />
        <Stack.Screen name="Community" component={CommunityMainBlock} options={noShadowNoBorderHeader} />
        <Stack.Screen name="Chat" component={ChatMainBlock} options={noShadowNoBorderHeader} />

        <Stack.Screen name="Payment" component={PaymentScreen} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ChatReview" component={ChatReviewComponent} options={noShadowNoBorderHeader} />

        <Stack.Screen name="ViewOrganisation" component={ViewOrganisation} options={noShadowNoBorderHeader} />
        <Stack.Screen name="EditOrganisation" component={EditOrganisation} options={noShadowNoBorderHeader} />

        <Stack.Screen name="SendMessage" component={SendMessage} options={noShadowNoBorderHeader} />
        <Stack.Screen name="MessageSent" component={MessageSent} options={noShadowNoBorderHeader} />

        <Stack.Screen
          name="AcceptInvitation"
          component={AcceptInvitation}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />

        <Stack.Screen
          name="MergeListings"
          component={MergeListing}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />

        <Stack.Screen name="Help" component={HelpScreen} />

        <Stack.Screen
          name="Login"
          component={LoginScreen}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />

        <Stack.Screen name="Forgot" component={ForgotScreen} options={{ ...customHeader }} />

        <Stack.Screen
          name="PasswordReset"
          component={PasswordResetScreen}
          options={{
            ...customHeader,
          }}
        />

        <Stack.Screen
          name="PasswordSent"
          component={PasswordSentScreen}
          options={{
            ...customHeader,
          }}
        />

        <Stack.Screen
          name="AccountType"
          component={AccountTypeScreen}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />

        <Stack.Screen
          name="Signup"
          component={SignupScreen}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />
        <Stack.Screen
          //@ts-ignore
          name="AccountRemovedScreen"
          component={AccountRemovedScreen}
          options={{ ...noShadowNoBorderHeader, headerLeft: () => undefined }}
        />

        <Stack.Screen
          name="SignupComplete"
          component={SignupCompleteScreen}
          options={{
            ...customHeader,
            headerLeft: () => undefined,
          }}
        />
      </Stack.Navigator>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openCalendarToast}
        autoHideDuration={6000}
        onClose={onToastClose}>
        <MuiAlert
          onClose={onToastClose}
          severity="warning"
          sx={{ width: '100%' }}
          action={
            <Button
              color="inherit"
              size="small"
              onClick={() => {
                linkTo({
                  screen: 'CreateListing',
                  params: {
                    id: userState.listing?.id,
                    skipToStep: 3,
                  },
                });
                onToastClose();
              }}>
              RECONNECT
            </Button>
          }>
          Your calendar has been disconnected.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AppNavigator;
