import React, { CSSProperties, useEffect, useState } from 'react';
import { Colour } from '../../../styles/style';
import avatarimg from '../../../assets/images/logo-chat.png';
import CommunityItem from './CommunityItem';
import {
  useGetAllCommunitiesQuery,
  useGetAllUsersQuery,
  useGetInvitationsByInviteeIdQuery,
} from '../../../api/queries';
import { Badge, Button, useMediaQuery } from '@mui/material';
import SearchBar from './ SearchBar';
import CommunityModal from '../modulCommunity/CommunityModal';
import { useCommunicatemutations } from '../../../api/mutations';
import { queryClient } from '../../../api/queryClient';
import { GET_ALL_COMMUNITIES } from '../../../constants/queryKeys';
import NotificationsIcon from '@mui/icons-material/Notifications';
import InvitationModal from '../modulCommunity/InvitationModal';

interface User {
  id: number;
  username: string;
  role: string;
}

interface CommunityData {
  name: string;
  description: string;
  image: string | null;
  admins: string[];
  members: string[];
  imageFile: File | null;
}

interface SideBarCommunityProps {
  setIdChat: React.Dispatch<React.SetStateAction<number | undefined>>;
  isVisible: boolean;
  toggleSidebar: () => void;
  isPermissionAdmin?: boolean;
  acceptInvite: (id: number) => void;
  declineInvite: (id: number) => void;
}

function SideBarCommunity({
  setIdChat,
  isVisible,
  toggleSidebar,
  isPermissionAdmin,
  acceptInvite,
  declineInvite,
}: SideBarCommunityProps) {
  const [searchValue, setSearchValue] = useState<string>('');
  const [selectedAdmins, setSelectedAdmins] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<string[]>([]);
  const [communityData, setCommunityData] = useState<CommunityData>({
    name: '',
    description: '',
    image: null,
    admins: [],
    members: [],
    imageFile: null,
  });
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openInvitationModal, setOpenInvitationModal] = useState(false);
  const { data: communityList = [] } = useGetAllCommunitiesQuery();
  const { data: userList = [] } = useGetAllUsersQuery();
  const { createCommunity, deleteCommunity } = useCommunicatemutations();
  const adminsList = userList.filter((user: User) => user.role === 'admin');
  const storedUserId = localStorage.getItem('userId');
  const { data: listIvitations } = useGetInvitationsByInviteeIdQuery(storedUserId ? +storedUserId : 0);

  const [errorCreateCommunity, setErrorCreateCommunity] = useState({
    errorAdmins: '',
    errorMembers: '',
    errorSubmit: '',
  });

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => {
    setOpenModal(false);
    setCommunityData({ name: '', description: '', image: null, admins: [], members: [], imageFile: null });
    setSelectedAdmins([]);
    setSelectedMembers([]);
    setErrorCreateCommunity({ errorAdmins: '', errorMembers: '', errorSubmit: '' });
  };

  const handleCreateCommunity = () => {
    if (selectedAdmins.length === 0) {
      return setErrorCreateCommunity({ ...errorCreateCommunity, errorAdmins: 'At least one admin must be selected' });
    }

    if (selectedMembers.length === 0) {
      return setErrorCreateCommunity({
        ...errorCreateCommunity,
        errorMembers: 'At least one user must be selected',
        errorAdmins: '',
      });
    }

    const formData = new FormData();
    formData.append('image', communityData.imageFile);
    formData.append('name', communityData.name);
    formData.append('description', communityData.description);
    formData.append('creatorId', storedUserId);
    selectedAdmins.forEach(admin => formData.append('admins[]', admin));
    selectedMembers.forEach(member => formData.append('members[]', member));

    console.log(communityData, selectedAdmins, selectedMembers, storedUserId, '123');

    createCommunity(formData, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [GET_ALL_COMMUNITIES] });
        setErrorCreateCommunity({ errorAdmins: '', errorMembers: '', errorSubmit: '' });
        handleCloseModal();
      },
      onError: err => {
        console.log('Create error:', err);
      },
    });
  };

  const deleteCommunityId = (id: number) => {
    deleteCommunity(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [GET_ALL_COMMUNITIES] });
      },
      onError: err => {
        console.log('Create error:', err);
        setErrorCreateCommunity({
          ...errorCreateCommunity,
          errorSubmit: 'Something went wrong while creating the community. Please try again.',
        });
      },
    });
  };

  const handleOpenInvitationModal = () => setOpenInvitationModal(true);
  const handleCloseInvitationModal = () => setOpenInvitationModal(false);

  const isMobile = useMediaQuery('(max-width:990px)');

  const styleDesktop: CSSProperties = {
    width: '20%',
    background: 'white',
    boxShadow: '3px 0px 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    paddingTop: 10,
    fontFamily: 'Barlow',
  };

  const styleMobile: CSSProperties = {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: isVisible ? 0 : '-100%',
    height: '100vh',
    backgroundColor: 'white',
    boxShadow: '3px 0px 5px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    paddingTop: 75,
    fontFamily: 'Barlow',
    transition: 'left 0.3s ease-in-out',
    zIndex: 100,
  };

  return (
    <>
      <div style={isMobile ? styleMobile : styleDesktop}>
        <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
        {isPermissionAdmin && (
          <Button
            sx={{ width: '80%', backgroundColor: Colour.LightBlue, padding: 1, borderRadius: 1, marginTop: 1 }}
            onClick={handleOpenModal}>
            Create Community
          </Button>
        )}

        {!!listIvitations?.length && (
          <Button
            onClick={handleOpenInvitationModal}
            sx={{
              width: '80%',
              backgroundColor: '#B3D4FF',
              color: '#1A73E8',
              padding: 1,
              borderRadius: 1,
              marginTop: 1,
              '&:hover': {
                backgroundColor: '#A3C4EE',
              },
            }}>
            <Badge
              badgeContent={listIvitations?.length}
              color="error"
              sx={{
                '& .MuiBadge-badge': {
                  top: 5,
                  right: 14,
                  fontSize: '0.5rem',
                  minWidth: 16,
                  height: 16,
                },
              }}>
              <NotificationsIcon sx={{ marginRight: 1, color: '#1A73E8' }} />
            </Badge>
            Invitations
          </Button>
        )}

        <CommunityModal
          open={openModal}
          onClose={handleCloseModal}
          communityData={communityData}
          setCommunityData={setCommunityData}
          adminsList={adminsList}
          userList={userList}
          selectedAdmins={selectedAdmins}
          setSelectedAdmins={setSelectedAdmins}
          selectedMembers={selectedMembers}
          setSelectedMembers={setSelectedMembers}
          onSave={handleCreateCommunity}
          errorCreateCommunity={errorCreateCommunity}
        />

        <div style={{ width: '90%', paddingTop: 10, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {communityList.length > 0 &&
            communityList
              .filter(item => item.name.toLowerCase().includes(searchValue.toLowerCase()))
              .map(item => (
                <CommunityItem
                  key={item.id}
                  name={item.name}
                  members={item.members ? item.members.length : 0}
                  avatarimg={item.image ?? avatarimg}
                  Colour={Colour}
                  getChatId={setIdChat}
                  id={item.id}
                  onDelete={deleteCommunityId}
                  toggleSidebar={toggleSidebar}
                  isAdmin={isPermissionAdmin}
                />
              ))}
        </div>
      </div>

      {isVisible && isMobile && (
        <div
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 90,
          }}
        />
      )}

      <InvitationModal
        open={openInvitationModal}
        onClose={handleCloseInvitationModal}
        avatar={avatarimg}
        listIvitations={listIvitations}
        acceptInvite={acceptInvite}
        declineInvite={declineInvite}
      />
    </>
  );
}

export default SideBarCommunity;
