import React, { useEffect } from 'react';
import { Modal, Box, Typography, List, ListItem, Avatar, Button, Divider } from '@mui/material';
import { Colour } from '../../../styles/style';
import { InvitationInviteeIdResponse } from '../../../api/types';
import { useLinkTo } from '@react-navigation/native';

interface InvitationModalProps {
  open: boolean;
  onClose: () => void;
  avatar: string;
  listIvitations?: InvitationInviteeIdResponse[];
  acceptInvite: (communityId: number) => void;
  declineInvite: (communityId: number) => void;
}

const InvitationModal: React.FC<InvitationModalProps> = ({
  open,
  onClose,
  avatar,
  listIvitations,
  acceptInvite,
  declineInvite,
}) => {
  const linkTo = useLinkTo();

  const handleAccept = (id: number) => {
    acceptInvite(id);
  };

  const handleDecline = (id: number) => {
    declineInvite(+id);
  };

  const viewCommunity = (id: number) => {
    localStorage.setItem('idChat', String(id));
    linkTo(`/community?id=${id}`);

    setTimeout(() => onClose(), 300);
  };

  useEffect(() => {
    if (listIvitations && listIvitations.length === 0) {
      onClose();
    }
  }, [listIvitations, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 420,
          bgcolor: Colour.White,
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}>
        <Typography variant="h6" component="h2" mb={3} sx={{ textAlign: 'center', color: Colour.DarkGrey }}>
          Invitations
        </Typography>
        <List>
          {listIvitations &&
            listIvitations.map(invite => (
              <React.Fragment key={invite.id}>
                <ListItem sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                  <Avatar src={avatar} sx={{ width: 50, height: 50 }} />
                  <Box flex="1">
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 500, fontFamily: 'Barlow', color: Colour.DarkGrey }}>
                      {invite.community.communityName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: Colour.MediumGrey, fontFamily: 'Barlow' }}>
                      Invited by: {invite.inviter.inviterName}
                    </Typography>
                    <Box mt={1} sx={{ display: 'flex', gap: 1 }}>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          backgroundColor: Colour.ViewBlue,
                          color: Colour.White,
                          '&:hover': { backgroundColor: Colour.ViewBlueHover },
                          fontFamily: 'Barlow',
                          transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => viewCommunity(invite.communityId)}>
                        View Community
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          color: Colour.AcceptGreen,
                          borderColor: Colour.AcceptGreen,
                          '&:hover': {
                            backgroundColor: `${Colour.AcceptGreen}20`,
                            borderColor: Colour.AcceptGreen,
                          },
                          fontFamily: 'Barlow',
                          transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => handleAccept(invite.communityId)}>
                        Accept
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          color: Colour.DeclineRed,
                          borderColor: Colour.DeclineRed,
                          '&:hover': {
                            backgroundColor: `${Colour.DeclineRed}20`,
                            borderColor: Colour.DeclineRed,
                          },
                          fontFamily: 'Barlow',
                          transition: 'background-color 0.3s ease',
                        }}
                        onClick={() => handleDecline(invite.communityId)}>
                        Decline
                      </Button>
                    </Box>
                  </Box>
                </ListItem>
                <Divider sx={{ bgcolor: Colour.Silver }} />
              </React.Fragment>
            ))}
        </List>
      </Box>
    </Modal>
  );
};

export default InvitationModal;
