import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useMesssagemutations = () => {
  const { mutate: createMessage } = useMutation({
    mutationFn: apiHandlers.messages.getAllMessagesByChatId,
  });

  const { mutate: deleteMessage } = useMutation({
    mutationFn: apiHandlers.messages.deleteMessage,
  });

  return {
    createMessage,
    deleteMessage,
  };
};
