import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_ALL_POSTS_BYID_COMMUNITY } from '../../constants/queryKeys';

export const useGetAllPostsByIdCommunityQuery = (id: number) => {
  return useQuery({
    queryKey: [GET_ALL_POSTS_BYID_COMMUNITY, id],
    queryFn: () => apiHandlers.posts.getAllPostsByIdCommunity(id).then(data => data.data),
  });
};
