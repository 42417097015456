import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_AAL_MESSAGES_ID } from '../../constants/queryKeys';

export const useGetAllMessagesByChatIdQuery = (id: number) => {
  return useQuery({
    queryKey: [GET_AAL_MESSAGES_ID, id],
    queryFn: () => apiHandlers.messages.getAllMessagesByChatId(id).then(data => data.data),
  });
};
