import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useSendInviteCommunityMutation = () => {
  const { mutate: updateInviteStatus } = useMutation({
    mutationFn: apiHandlers.invitationCommunity.updateInvitationStatus,
  });

  const { mutate: sendInviteCommunity } = useMutation({
    mutationFn: apiHandlers.invitationCommunity.sendInviteCommunity,
  });

  return {
    sendInviteCommunity,
    updateInviteStatus,
  };
};
