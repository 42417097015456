import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Linking, Modal, Text, TouchableOpacity, View } from 'react-native';
import { Colour } from '../../styles/style';

interface CalendarAccessModalProps {
  visible: boolean;
  onClose: () => void;
  title?: string;
  message?: string;
  buttonText?: string;
}

const CalendarAccessModal: React.FC<CalendarAccessModalProps> = ({ visible, onClose, title, message, buttonText }) => {
  const navigation = useNavigation();

  const handleContactPress = () => {
    Linking.openURL('https://www.auslawconcierge.com.au/contact');
  };

  const handleClose = () => {
    onClose();
    navigation.goBack();
  };
  return (
    <Modal animationType="slide" transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0,0,0,0.5)' }}>
        <View style={{ backgroundColor: 'white', padding: 20, borderRadius: 10, alignItems: 'center' }}>
          <Text style={{ fontSize: 18, marginBottom: 10 }}>{title}</Text>
          <Text style={{ fontSize: 16, marginBottom: 20 }}>{message}</Text>
          <TouchableOpacity
            onPress={handleContactPress}
            style={{ backgroundColor: Colour.Blue, padding: 10, borderRadius: 5 }}>
            <Text style={{ color: 'white', fontSize: 16 }}>{buttonText}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={handleClose} style={{ marginTop: 10 }}>
            <Text style={{ color: Colour.Blue, fontSize: 16 }}>Close</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default CalendarAccessModal;
