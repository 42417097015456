import React, { useState } from 'react';
import { useGetInvitationsByCommunityIdQuery } from '../../../api/queries';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  TextField,
  IconButton,
  Box, // Import TextField for the search input
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Colour } from '../../../styles/style';

interface AdminInviteListProps {
  idCommunity?: number;
}

function AdminListInviteUser(props: AdminInviteListProps) {
  const { idCommunity } = props;
  const { data: inviteUsers, isLoading } = useGetInvitationsByCommunityIdQuery(idCommunity ?? 0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [nameSearch, setNameSearch] = useState('');
  const [isSearchName, setIsSearchName] = useState(false); // State for the name search input

  const uniqueInvitations = inviteUsers
    ? Array.from(
        inviteUsers
          .reduce((map, invite) => {
            if (
              (!map.has(invite.inviteeId) ||
                new Date(invite.createdAt) > new Date(map.get(invite.inviteeId).createdAt)) &&
              invite.status === 'pending'
            ) {
              map.set(invite.inviteeId, invite);
            }
            return map;
          }, new Map())
          .values(),
      ).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
    : [];

  // Filter invitations based on the name search
  const filteredInvitations = uniqueInvitations.filter(
    invite => invite.invitee?.inviteeName.toLowerCase().includes(nameSearch.toLowerCase()),
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (inviteUsers && inviteUsers.length === 0) {
    return <></>;
  }

  return (
    <>
      {uniqueInvitations.length > 0 && (
        <TableContainer component={Paper} elevation={4} style={{ borderRadius: '12px', maxWidth: '90%' }}>
          <Typography
            variant="h6"
            component="div"
            style={{
              padding: '8px',
              backgroundColor: Colour.Blue,
              color: 'white',
              borderTopLeftRadius: '12px',
              borderTopRightRadius: '12px',
              textAlign: 'center',
            }}>
            List of invited users
          </Typography>

          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                <TableCell style={{ fontWeight: 'bold', width: '6%' }}>№</TableCell>
                <TableCell style={{ fontWeight: 'bold', width: '30%' }}>
                  {!isSearchName ? (
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1">Name</Typography>
                      <IconButton size="small" onClick={() => setIsSearchName(true)}>
                        <SearchIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  ) : (
                    <Box display="flex" alignItems="center" position="relative" width="100%">
                      <TextField
                        variant="standard"
                        size="small"
                        value={nameSearch}
                        onChange={e => setNameSearch(e.target.value)}
                        placeholder="Search by name"
                        style={{ width: '350px' }}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              size="small"
                              onClick={() => {
                                setIsSearchName(false);
                                setNameSearch('');
                              }}>
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </TableCell>
                <TableCell style={{ fontWeight: 'bold', width: '20%' }}>Date</TableCell>
                <TableCell style={{ fontWeight: 'bold', width: '20%' }}>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInvitations.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{row.invitee.inviteeName}</TableCell>
                  <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>{row.status}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={filteredInvitations.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
}

export default AdminListInviteUser;
