import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TablePagination,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { Colour } from '../../../styles/style';
import { AllCommunitiesResponse } from '../../../api/types';
import { useAdminMutation } from '../../../api/mutations';
import { queryClient } from '../../../api/queryClient';
import { GET_ALL_COMMUNITIES, GET_ALL_USERS_ADMIN, GET_CHAT_BY_CHATID } from '../../../constants/queryKeys';
import AdminListInviteUser from './AdminListInviteUser';

interface AdminPanelProps {
  communityData?: AllCommunitiesResponse;
}

function AdminPanel(props: AdminPanelProps) {
  const { communityData } = props;

  const [editRoleId, setEditRoleId] = useState();
  const [roleFilter, setRoleFilter] = useState('');
  const [nameSearch, setNameSearch] = useState(''); // State for the name search input
  const [openDialog, setOpenDialog] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [page, setPage] = useState(0);
  const [isEditName, setIsEditName] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { editUserRole, deleteUserCommunity } = useAdminMutation();

  const userId = localStorage.getItem('userId');

  const filteredData =
    communityData &&
    communityData.usersInfo
      .map(user => {
        // Перевіряємо чи існує `admins` і чи включає воно `user.id`
        const role = communityData.admins?.includes(user.id.toString()) ? 'Admin' : 'User';

        return {
          ...user,
          role,
        };
      })
      .filter(user => {
        // Фільтрація за роллю та пошук за іменем
        return (
          (roleFilter === '' || user.role === roleFilter) &&
          user.username.toLowerCase().includes(nameSearch.toLowerCase())
        );
      })
      .reverse();

  const handleRoleChange = (id: number, newRole: string) => {
    if (communityData) {
      const data = {
        communityId: String(communityData?.id),
        role: newRole.toLowerCase(),
      };

      editUserRole(
        { idUser: id, data },
        {
          onSuccess: () => {
            console.log('Success');
            queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, communityData?.id] });
            queryClient.invalidateQueries({ queryKey: [GET_ALL_USERS_ADMIN] });
          },
          onError: err => {
            console.log(err);
          },
        },
      );
      setEditRoleId(null);
    }
  };

  const handleEditClick = id => {
    setEditRoleId(id);
  };

  const handleDeleteClick = id => {
    setUserToDelete(id);
    setOpenDialog(true);
  };

  const confirmDelete = () => {
    if (userToDelete && communityData) {
      const data = {
        communityId: communityData?.id,
      };
      deleteUserCommunity(
        { idUser: userToDelete, data },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, communityData?.id] });
            queryClient.invalidateQueries({ queryKey: [GET_ALL_COMMUNITIES] });
            setOpenDialog(false);
            setUserToDelete(null);
          },
          onError: err => {
            console.log(err);
          },
        },
      );
    }
  };

  const cancelDelete = () => {
    setOpenDialog(false);
    setUserToDelete(null);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box display="flex" flexDirection="column" gap={5} justifyContent="center" alignItems="center" p={3}>
      <TableContainer component={Paper} elevation={4} style={{ borderRadius: '12px', maxWidth: '90%' }}>
        <Typography
          variant="h6"
          component="div"
          style={{
            padding: '8px',
            backgroundColor: Colour.Blue,
            color: 'white',
            borderTopLeftRadius: '12px',
            borderTopRightRadius: '12px',
            textAlign: 'center',
          }}>
          User Administration Panel
        </Typography>
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: '#f5f5f5' }}>
              <TableCell style={{ fontWeight: 'bold', width: '5%' }}>№</TableCell>
              <TableCell style={{ fontWeight: 'bold', width: '30%' }}>
                {!isEditName ? (
                  <Box display="flex" alignItems="center">
                    <Typography variant="body1">Name</Typography>
                    <IconButton size="small" onClick={() => setIsEditName(true)}>
                      <SearchIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" position="relative" width="100%">
                    <TextField
                      variant="standard"
                      size="small"
                      value={nameSearch}
                      onChange={e => setNameSearch(e.target.value)}
                      placeholder="Search by name"
                      style={{ width: '350px' }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            size="small"
                            onClick={() => {
                              setIsEditName(false);
                              setNameSearch('');
                            }}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    />
                  </Box>
                )}
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', width: '20%' }}>
                <FormControl variant="standard" style={{ width: '100%' }}>
                  <Select value={roleFilter} onChange={e => setRoleFilter(e.target.value)} displayEmpty>
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="Admin">Admin</MenuItem>
                    <MenuItem value="User">User</MenuItem>
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell style={{ fontWeight: 'bold', width: '10%' }}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index: number) => (
                <TableRow key={row.id} style={{ backgroundColor: index % 2 === 0 ? '#f9f9f9' : '#ffffff' }}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>
                    {editRoleId === row.id ? (
                      <FormControl variant="standard" style={{ width: '100%' }}>
                        <Select
                          value={row.role}
                          onChange={e => handleRoleChange(row.id, e.target.value)}
                          onBlur={() => setEditRoleId(null)}
                          autoFocus>
                          <MenuItem value="Admin">Admin</MenuItem>
                          <MenuItem value="User">User</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <Box display="flex" alignItems="center">
                        <Typography style={{ minWidth: 45 }}>{row.role}</Typography>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {+userId !== row.id && (
                      <>
                        <IconButton onClick={() => handleEditClick(row.id)} size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={() => handleDeleteClick(row.id)} size="small" style={{ color: 'red' }}>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  No users found with the selected role and search criteria.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <AdminListInviteUser idCommunity={communityData?.id} />

      <Dialog open={openDialog} onClose={cancelDelete}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default AdminPanel;
