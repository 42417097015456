import React from 'react';
import { Paper, Typography } from '@mui/material';
import PublicIcon from '@mui/icons-material/Public';
import VisibilityIcon from '@mui/icons-material/Visibility';
import HistoryIcon from '@mui/icons-material/History';
import DescriptionIcon from '@mui/icons-material/Description';

interface AboutCommunityProps {
  Colour: { Blue: string };
  description?: string;
  dataCreate?: string;
  dataUpdate?: string;
}

const AboutCommunity: React.FC<AboutCommunityProps> = ({ Colour, description, dataCreate, dataUpdate }) => {
  const formatDate = (isoDate: string): string => {
    const date = new Date(isoDate);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  };

  return (
    <Paper
      elevation={3}
      sx={{
        padding: '20px',
        width: '80%',
        borderRadius: '12px',
        marginLeft: '20px',
        marginBottom: '20px',
        backgroundColor: '#f5f5f5',
      }}>
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{
          color: 'black',
          marginBottom: '16px',
        }}>
        About this group
      </Typography>

      {description && (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <DescriptionIcon sx={{ color: Colour.Blue, marginRight: '8px' }} />
          <Typography variant="body1">
            <b>Description: </b>
            {description}
          </Typography>
        </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <PublicIcon sx={{ color: Colour.Blue, marginRight: '8px' }} />
        <Typography variant="body1">
          <b>Public</b>: Anyone can see who’s in the group and what they post.
        </Typography>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <VisibilityIcon sx={{ color: Colour.Blue, marginRight: '8px' }} />
        <Typography variant="body1">
          <b>Visible</b>: Anyone can find this group.
        </Typography>
      </div>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <HistoryIcon sx={{ color: Colour.Blue, marginRight: '8px' }} />
        <Typography variant="body1">
          <b>History</b>: {`The group was created on ${formatDate(dataCreate ?? '')}.`}
        </Typography>
      </div>
    </Paper>
  );
};

export default AboutCommunity;
