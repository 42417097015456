import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_ALL_CHATS_ID } from '../../constants/queryKeys';

export const useGetAllChatByIdQuery = (id: number) => {
  return useQuery({
    queryKey: [GET_ALL_CHATS_ID, id],
    queryFn: async () => (await apiHandlers.chats.getAllChatsById(id)).data,
  });
};
