import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_ALL_COMMUNITIES } from '../../constants/queryKeys';

export const useGetAllCommunitiesQuery = () => {
  return useQuery({
    queryKey: [GET_ALL_COMMUNITIES],
    queryFn: () => apiHandlers.communities.getAllCommunities().then(data => data.data),
  });
};
