import React from 'react';
import { Paper, TextField, IconButton, Button, useMediaQuery, Tooltip } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';

interface PostFormProps {
  postContent: string;
  handlePostChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleImageUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePostSubmit: () => void;
  image: string | null;
  userIsJoin?: boolean | '' | null;
  isPermissionAdmin: boolean;
}

const PostForm: React.FC<PostFormProps> = ({
  postContent,
  handlePostChange,
  handleImageUpload,
  handlePostSubmit,
  image,
  userIsJoin,
  isPermissionAdmin,
}) => {
  const isMobile = useMediaQuery('(max-width:990px)');
  return (
    <Paper
      elevation={3}
      sx={{
        padding: '20px',
        width: isMobile ? 'auto' : '70%',
        borderRadius: '8px',
        marginInline: '20px',
      }}>
      <TextField
        label="What's on your mind?"
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={postContent}
        onChange={handlePostChange}
        inputProps={{ maxLength: 1000 }}
        sx={{
          marginBottom: '20px',
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
            backgroundColor: '#fff',
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.05)',
            '& fieldset': {
              borderColor: '#e0e0e0',
            },
            '&:hover fieldset': {
              borderColor: '#007bff',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#007bff',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#666',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#007bff',
          },
        }}
      />

      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <input
          accept="image/*"
          style={{ display: 'none' }}
          id="icon-button-file"
          type="file"
          onChange={handleImageUpload}
        />
        <label htmlFor="icon-button-file">
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            sx={{
              backgroundColor: '#e3f2fd',
              borderRadius: '50%',
              '&:hover': {
                backgroundColor: '#bbdefb',
              },
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
            }}>
            <PhotoCamera />
          </IconButton>
        </label>

        {image && (
          <img
            src={image}
            alt="Preview"
            style={{
              width: 50,
              height: 50,
              objectFit: 'cover',
              borderRadius: 8,
              boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
              marginRight: '10px',
            }}
          />
        )}

        <Tooltip title={!userIsJoin ? 'Join the community to create a post' : ''} arrow>
          <span>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePostSubmit}
              disabled={!(userIsJoin || isPermissionAdmin)}
              sx={{
                borderRadius: '8px',
                textTransform: 'none',
                padding: '10px 30px',
                backgroundColor: '#007bff',
                boxShadow: '0px 4px 12px rgba(0, 123, 255, 0.3)',
                '&:hover': {
                  backgroundColor: userIsJoin || isPermissionAdmin ? '#0056b3' : '#007bff',
                },
              }}>
              Post
            </Button>
          </span>
        </Tooltip>
      </div>
    </Paper>
  );
};

export default PostForm;
