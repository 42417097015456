import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLinkTo } from '@react-navigation/native';

interface CommunityItemProps {
  name: string;
  members?: number;
  avatarimg: string;
  Colour: { Silver: string; DarkGrey: string; Grey: string };
  key: any;
  getChatId: (id: number) => void;
  id: number;
  onDelete: (id: number) => void;
  toggleSidebar: () => void;
  isAdmin?: boolean | null | number;
}

const CommunityItem: React.FC<CommunityItemProps> = ({
  name,
  members,
  avatarimg,
  Colour,
  getChatId,
  id,
  toggleSidebar,
  isAdmin,
}) => {
  const linkTo = useLinkTo();

  const openCommunity = () => {
    toggleSidebar();
    localStorage.setItem('idChat', String(id));
    linkTo(`/community?id=${id}`);
    getChatId(id);
  };

  return (
    <div
      onClick={() => openCommunity()}
      style={{
        width: '100%',
        borderRadius: 8,
        borderBottom: `1px solid ${Colour.Silver}`,
        display: 'flex',
        gap: 15,
        paddingBottom: 5,
        alignItems: 'center',
        cursor: 'pointer',
        marginTop: 5,
        position: 'relative',
      }}>
      <img src={avatarimg} style={{ width: '50px', height: '50px', borderRadius: 8 }} />
      <div>
        <p style={{ fontSize: 13, fontWeight: 500, color: Colour.DarkGrey, margin: 0, display: 'block' }}>{name}</p>
        {members && (
          <p style={{ fontSize: 10, color: Colour.Silver, margin: 0, display: 'block' }}>{members} members</p>
        )}
      </div>
    </div>
  );
};

export default CommunityItem;
