import { AxiosResponse } from 'axios';
import { apiPath } from './apiPath';
import { deleteData, getData, patchData, postData, putData } from './crudHandlers';
import {
  AxiosResponseSuccess,
  createMessagesResponse,
  AuthBody,
  LoginResponse,
  RegistrationResponse,
  UsersList,
  CreateChatBody,
  CreateChatResponse,
  ChatsResponse,
  MessagesResponse,
  AllCommunitiesResponse,
  DataCreateCommunity,
  AllPostResponse,
  AdminUserResponse,
  editRoleUserData,
  sendInviteData,
  InvitationInviteeIdResponse,
  editInvitationStatus,
} from './types';

export const apiHandlers = {
  auth: {
    login: (data: AuthBody): AxiosResponseSuccess<LoginResponse> => {
      return postData(apiPath.auth.login(), {
        data,
      });
    },
    register: (data: AuthBody): AxiosResponseSuccess<RegistrationResponse> => {
      return postData(apiPath.auth.register(), {
        data,
      });
    },
  },
  users: {
    getAllUsers: (): AxiosResponseSuccess<UsersList> => {
      return getData(apiPath.users.getAllUsers());
    },
  },
  chats: {
    createChat: (data: CreateChatBody): AxiosResponseSuccess<CreateChatResponse> => {
      return postData(apiPath.chats.createChat(), {
        data,
      });
    },
    getAllChatsById: (id: number): AxiosResponseSuccess<ChatsResponse> => {
      return getData(apiPath.chats.getChatById(id));
    },
    editChatById: async ({ id, data }: { id: number; data: { chatName: string } }): Promise<AxiosResponse> => {
      return patchData(apiPath.chats.editChat(id), data);
    },
    deleteChatById: (id: number): AxiosResponseSuccess<unknown> => {
      return deleteData(apiPath.chats.deleteChat(id));
    },
  },
  messages: {
    getAllMessagesByChatId: (id: number): AxiosResponseSuccess<MessagesResponse> => {
      return getData(apiPath.messages.getAllMessagesByChatId(id));
    },
    createMessage: (data: any): AxiosResponseSuccess<createMessagesResponse> => {
      return postData(apiPath.messages.createMessage(), {
        data,
      });
    },
    deleteMessage: (id: number): AxiosResponseSuccess<createMessagesResponse> => {
      return deleteData(apiPath.messages.deleteMessage(id));
    },
  },
  communities: {
    getAllCommunities: (): AxiosResponseSuccess<AllCommunitiesResponse[]> => {
      return getData(apiPath.communities.geAllCommunities());
    },
    getChatByIdChat: (idChat: number | undefined): AxiosResponseSuccess<AllCommunitiesResponse> => {
      return getData(apiPath.communities.getCommunityByIdChat(idChat));
    },
    createCommunity: (data: DataCreateCommunity): AxiosResponseSuccess<AllCommunitiesResponse> => {
      return postData(apiPath.communities.createCommunity(), {
        data,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
    deleteCommunity: (id: number): AxiosResponseSuccess<AllCommunitiesResponse> => {
      return deleteData(apiPath.communities.deleteCommunity(id));
    },
    addUserCommunity: ({ id, data }: { id: number; data: any }): AxiosResponseSuccess<MessagesResponse> => {
      return postData(apiPath.communities.addUsetToCommunity(id), { data });
    },
    deleteUserCommunity: ({ idCommunity, idUser }: any): AxiosResponseSuccess<any> => {
      return deleteData(apiPath.communities.deleeUserToCommunity(idCommunity, idUser));
    },
    updateCommunity: ({ id, data }: { id: number; data: any }): AxiosResponseSuccess<any> => {
      return patchData(apiPath.communities.updateCommunity(id), data);
    },
  },
  posts: {
    getAllPostsByIdCommunity: (id: number): AxiosResponseSuccess<AllPostResponse[]> => {
      return getData(apiPath.posts.getAllPostsByIdCommunity(id));
    },
    deletePost: (id: number): AxiosResponseSuccess<any> => {
      return deleteData(apiPath.posts.deletePost(id));
    },
  },
  admins: {
    getAllUserAdmin: (): AxiosResponseSuccess<AdminUserResponse[]> => {
      return getData(apiPath.admins.getAllUserAdmin());
    },
    editUserRole: ({ idUser, data }: { idUser: number; data: editRoleUserData }): AxiosResponseSuccess<any> => {
      return patchData(apiPath.admins.editUserRole(idUser), data);
    },
    deleteUserCommunity: ({
      idUser,
      data,
    }: {
      idUser: number;
      data: { communityId: number };
    }): AxiosResponseSuccess<any> => {
      return deleteData(apiPath.admins.deleteUserCommunity(idUser), { data });
    },
  },
  invitationCommunity: {
    sendInviteCommunity: ({ data }: { data: sendInviteData }): AxiosResponseSuccess<any> => {
      return postData(apiPath.invitationCommunity.sendInviteCommunity(), { data });
    },
    getInvitationsByCommunityId: (idCommunity: number): AxiosResponseSuccess<any> => {
      return getData(apiPath.invitationCommunity.getInvitationsByCommunityId(idCommunity));
    },
    getInvitationsByInviteeId: (idUser: number): AxiosResponseSuccess<InvitationInviteeIdResponse[]> => {
      return getData(apiPath.invitationCommunity.getInvitationsByInviteeId(idUser));
    },
    updateInvitationStatus: ({
      statusId,
      data,
    }: {
      statusId: number;
      data: editInvitationStatus;
    }): AxiosResponseSuccess<any> => {
      return patchData(apiPath.invitationCommunity.updateInvitationStatus(statusId), data);
    },
  },
};
