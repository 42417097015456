import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_CHAT_BY_CHATID } from '../../constants/queryKeys';

export const useGetCommunitiesByChatIdQuery = (idChat: number | undefined) => {
  return useQuery({
    queryKey: [GET_CHAT_BY_CHATID, idChat],
    queryFn: () => apiHandlers.communities.getChatByIdChat(idChat).then(data => data.data),
  });
};
