export const apiPath = {
  auth: {
    login: () => '/auth/login',
    register: () => '/auth/register',
  },
  users: {
    getUser: (userId: number) => `/users/${userId}`,
    getAllUsers: () => '/users',
  },
  chats: {
    createChat: () => '/chats',
    getChatById: (id: number) => `/chats/user/${id}`,
    editChat: (id: number) => `/chats/${id}`,
    deleteChat: (id: number) => `/chats/${id}`,
  },
  messages: {
    chatHistory: (chatId: number) => `/messages/chat/${chatId}`,
    getAllMessagesByChatId: (chatId: number) => `/messages/chat/${chatId}`,
    createMessage: () => '/messages',
    editMessage: (id: number) => `/messages/${id}`,
    deleteMessage: (id: number) => `/messages/${id}`,
  },
  communities: {
    geAllCommunities: () => '/communities',
    getCommunityByIdChat: (idChat: number | undefined) => `/communities/${idChat}`,
    createCommunity: () => '/communities',
    deleteCommunity: (id: number) => `/communities/${id}`,
    addUsetToCommunity: (id: number) => `/communities/${id}/members`,
    deleeUserToCommunity: (idCommunity: number, idUser: number) => `/communities/${idCommunity}/members/${idUser}`,
    updateCommunity: (id: number) => `/communities/${id}`,
  },
  posts: {
    getAllPostsByIdCommunity: (id: number) => `/posts/community/${id}`,
    deletePost: (id: number) => `/posts/${id}`,
  },
  admins: {
    getAllUserAdmin: () => `/community-admin/admins`,
    editUserRole: (idUser: number) => `/community-admin/users/${idUser}/role`,
    deleteUserCommunity: (idUser: number) => `/community-admin/users/${idUser}`,
  },
  invitationCommunity: {
    sendInviteCommunity: () => `/community-admin/invite`,
    getInvitationsByCommunityId: (idCommunity: number) => `/invitations/${idCommunity}`,
    getInvitationsByInviteeId: (idUser: number) => `/invitations/invitee/${idUser}`,
    updateInvitationStatus: (statusId: number) => `/invitations/status/${statusId}`,
  },
};
