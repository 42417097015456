import moment, { Moment } from 'moment';
import React from 'react';
import { View } from 'react-native';
import style, { Colour } from '../../styles/style';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Text from '../Text';
import { DateProps, SetDateProps } from './CalendarHelper';

const MonthStrip = (props: DateProps & SetDateProps) => {
  const { date, setDate } = props;

  const getFirstWorkDayOfMonth = (currentDate: Moment) => {
    let firstDayOfNextMonth = moment(currentDate).add(1, 'month').startOf('month');

    while (firstDayOfNextMonth.day() !== 1) {
      firstDayOfNextMonth.add(1, 'days');
    }

    return firstDayOfNextMonth;
  };

  const getFirstMondayOfPreviousMonth = (currentDate: Moment) => {
    let firstDayOfPreviousMonth = moment(currentDate).subtract(1, 'month').startOf('month');

    while (firstDayOfPreviousMonth.day() !== 1) {
      firstDayOfPreviousMonth.subtract(1, 'days');
    }

    return firstDayOfPreviousMonth;
  };

  return (
    <View style={[style.rowFixed, { backgroundColor: '#00000011' }]}>
      <View style={{ padding: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
        <KeyboardDoubleArrowLeftIcon
          sx={{ color: Colour.DarkBlue }}
          onClick={() => setDate(getFirstMondayOfPreviousMonth(date))}
        />
        <KeyboardArrowLeftIcon
          sx={{ color: Colour.DarkBlue }}
          onClick={() => setDate(moment(date).subtract(1, 'week'))}
        />
      </View>
      <View style={{ alignSelf: 'center', flexGrow: 1 }}>
        <Text fontFamily="Barlow-Medium" style={[style.rowCenter, { alignSelf: 'center', letterSpacing: 0.8 }]}>
          {date.format('MMMM YYYY').toUpperCase()}
        </Text>
      </View>
      <View style={{ padding: 10, cursor: 'pointer', display: 'flex', flexDirection: 'row' }}>
        <KeyboardArrowRightIcon
          sx={{ color: Colour.DarkBlue }}
          testID="calendar-right"
          onClick={() => setDate(moment(date).add(1, 'week'))}
        />
        <KeyboardDoubleArrowRightIcon
          sx={{ color: Colour.DarkBlue }}
          onClick={() => {
            setDate(getFirstWorkDayOfMonth(date));
            getFirstWorkDayOfMonth(date);
          }}
        />
      </View>
    </View>
  );
};

export default MonthStrip;
