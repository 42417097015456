import { PaymentMethod, Stripe, loadStripe } from '@stripe/stripe-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Alert, Animated, Image, Platform, ScrollView, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import apay from '../../assets/images/apay@3x.png';
import gpay from '../../assets/images/gpay@3x.png';
import ActionSheet from '../../components/ActionSheet';
import { PrimaryButton, TextButton } from '../../components/Button';
import Chip from '../../components/Chip';
import FullscreenActivityIndicator from '../../components/FullscreenAcitivityIndicator';
import { useSafeBottomPadding } from '../../components/Hooks';
import ModalPicker from '../../components/ModalPicker';
import Text from '../../components/Text';
import { Link } from '../../components/TextHelper';
import { PaymentScreenProps } from '../../navigators/Types';
import { UserType } from '../../redux/Register';
import { stripeSelector, userStateSelector } from '../../redux/Store';
import { cancelInvite, fetchOrgListings, Profile, setProfile } from '../../redux/User';
import { CancelSubscription, ProfileDocument, logAnalyticsEvent } from '../../services/Firebase';
import { DoPayment, PaymentDetails, SubscriptionPeriod } from '../../services/StripeService';
import Style, { Barlow } from '../../styles/style';
import { formatMoney } from '../../tools/StringExtensions';
import LoginBase from '../auth/LoginBase';
import CreditCardPaymentBox from './components/CreditCardPaymentBox';
import PaymentHelperBox from './components/PaymentHelperBox';
import { PricingTierModalItem } from './utils/constants';
import { deleteField, updateDoc } from 'firebase/firestore/lite';
import { Elements } from '@stripe/react-stripe-js';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import config from '../../config.json';
import { captureException } from '@sentry/react';
import { useAuthmutations } from '../../api/mutations';
const PaymentScreen = ({ route, navigation }: PaymentScreenProps) => {
  const dispatch = useDispatch();
  const userState = useSelector(userStateSelector);
  const { registerMutate } = useAuthmutations();

  const { products } = useSelector(stripeSelector);
  const { orgListings, orgInvites } = useSelector(userStateSelector);

  const stripe = loadStripe(config.stripeApiKey);

  const userType = userState.profile?.userType;
  const existingPlan = userState.profile?.plan;

  const [paymentDetail, setPaymentDetail] = useState<PaymentDetails>();

  const [showModalPaymentCreditCard, setShowModalPaymentCreditCard] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState<string | null>();
  const [selectedPeriod, setSelectedPeriod] = useState<SubscriptionPeriod>(
    existingPlan?.period
      ? existingPlan?.period
      : userState.profile?.isDoyles
      ? SubscriptionPeriod.Yearly
      : SubscriptionPeriod.Monthly,
  );

  const [selectedPriceIndex, setSelectedPriceIndexTemp] = useState<number>(0);

  const [paymentTypes, setPaymentTypes] = useState<(string | React.ReactElement)[]>(['Pay by card']);

  const [busy, setBusy] = useState<boolean>();

  const setSelectedPriceIndex = useCallback((newIndex: number) => {
    if (newIndex === -1) {
      return;
    }
    setSelectedPriceIndexTemp(newIndex);
  }, []);

  const [priceId, selectablePrices] = useMemo(() => {
    if (products && userState.profile) {
      // Get product for usertype
      const product = products.find(one => one.id === userState.profile?.userType);

      if (product) {
        let plan;
        if (userState.profile?.isDoyles) {
          plan = product.plans.find(one => one.subscriptionPeriod === selectedPeriod && one.isDoyles);
        } else {
          plan = product.plans.find(one => one.subscriptionPeriod === selectedPeriod);
        }

        let prices: PricingTierModalItem[] | undefined;

        if (plan) {
          setSelectedPriceIndex(plan.prices.findIndex(price => price.amount === existingPlan?.profileAmount));
          prices = plan.prices.map(price => ({
            ...price,
            title: price.title ?? '',
            disabled: false,
            // disabled: userType === UserType.Organisation && (existingPlan?.profileAmount ?? -1) > price.amount!
          }));
        }
        return [plan?.priceId, prices];
      }
    }
    return [undefined, undefined];
  }, [products, userState.profile, selectedPeriod, setSelectedPriceIndex, existingPlan?.profileAmount]);

  const selectedPrice = useMemo(() => {
    if (selectablePrices !== undefined) {
      return selectablePrices[selectedPriceIndex];
    }
  }, [selectablePrices, selectedPriceIndex]);

  const perDurationAnim = new Animated.Value(0);

  const perDurationAnimTranslate = perDurationAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [selectedPeriod === 0 ? -50 : 50, 0],
  });

  useEffect(
    () =>
      Animated.timing(perDurationAnim, {
        toValue: 1,
        duration: 250,
        useNativeDriver: true,
      }).start(),
    [perDurationAnim, selectedPeriod],
  );

  const updatePaymentResult = React.useCallback(
    async (paymentMethod: PaymentMethod) => {
      setBusy(true);
      if (!paymentDetail) {
        return;
      }

      await DoPayment(paymentMethod.id, paymentDetail)
        .then(paymentResult => {
          setShowModalPaymentCreditCard(false);

          const dataUser = {
            username: userState.profile?.name,
            password: userState.auth?.userId,
          };

          if (paymentResult.success) {
            registerMutate(dataUser, {
              onSuccess: data => {
                localStorage.setItem('userId', data.data.user.id);

                socket.emit('sendMessage', data);
              },
              onError: err => {
                console.log('Registration error:', err);
              },
            });

            dispatch(setProfile({ ...userState.profile!, plan: paymentResult.plan }));

            logAnalyticsEvent('payment_attempted', {
              success: true,
            });

            if (route.params?.createListing) {
              navigation.replace('CreateListing', { id: userState?.listing?.id });
            } else {
              Alert.alert('Success', 'Your subscription has started');
            }
          } else if (paymentResult.statusCode === 500) {
            logAnalyticsEvent('payment_attempted', {
              success: false,
            });
            if (paymentResult.error?.code === 'card_declined') {
              setErrorDialog('Card Declined');
            } else {
              setErrorDialog('Payment Failed');
            }
          } else {
            logAnalyticsEvent('payment_attempted', {
              success: false,
            });
          }
        })
        .catch(reason => {
          alert('Payment rejected');
          captureException(reason);
        })
        .finally(() => {
          setBusy(false);
        });
    },
    [
      dispatch,
      navigation,
      paymentDetail,
      route.params?.createListing,
      route.params?.invite,
      userState.listing,
      userState.profile,
    ],
  );

  const handlePayment = useCallback(
    (isManual?: boolean) => {
      if (priceId === undefined || selectedPrice === undefined) {
        return;
      }

      if (userState.profile?.parentProfileId) {
        Alert.alert('Subscription Exists', 'You must leave organisation');
        return;
      }
      if (userType === UserType.Individual) {
        selectedPrice.amount = selectedPrice.amount ?? 1;
      }
      if (existingPlan?.subscriptionId) {
        setShowDialog(true);
        return;
      } else {
        setPaymentDetail({
          priceId: priceId,
          subscriptionPeriod: selectedPeriod,
          profileAmount: selectedPrice.amount,
          isDoyles: userState.profile?.isDoyles,
        });
      }
      setShowModalPaymentCreditCard(true);
    },
    [priceId, selectedPeriod, selectedPrice],
  );

  const mustCancelSubscription = useMemo(() => {
    return !(
      // Does user have a subscription plan?
      (
        existingPlan === undefined ||
        existingPlan.subscriptionId === undefined ||
        // If selected period is not same as plan period
        existingPlan.period !== selectedPeriod ||
        // If user is org, check if the profile selected practitioners isn't the same
        (userType === UserType.Organisation &&
          selectedPrice !== undefined &&
          existingPlan.profileAmount !== selectedPrice.amount)
      )
    );
  }, [existingPlan, selectedPeriod, selectedPrice, userType]);

  return (
    <LoginBase hasHeader>
      {products === undefined || priceId === undefined || selectedPrice === undefined ? (
        <ActivityIndicator />
      ) : (
        <View
          style={Style.scrollview}
          // eslint-disable-next-line react-hooks/rules-of-hooks
        >
          {userState.profile?.isDoyles ? null : (
            <PaymentHelperBox
              userType={userType}
              subscriptionPeriod={selectedPeriod}
              onSetSubscription={setSelectedPeriod}
            />
          )}

          <View style={{ alignItems: 'center' }}>
            {userType === UserType.Organisation && (
              <ModalPicker
                buttonContainerStyle={{ marginVertical: 10 }}
                selectionText="Number of individual profiles: "
                list={selectablePrices!.map(price => ({
                  title: price.title,
                  disabled: price.disabled,
                }))}
                selectedIndex={selectedPriceIndex}
                onChange={index => {
                  if (selectablePrices) {
                    const selectedAmount = selectablePrices[index].amount!;
                    // Check if the team is too large for selected plan
                    if (selectedAmount < (orgListings?.length || 0) + (orgInvites?.length || 0)) {
                      setTimeout(
                        () =>
                          Alert.alert(
                            'Team is too large',
                            'You must remove team members before you can downgrade your plan',
                          ),
                        250,
                      );
                      return;
                    }
                  }

                  setSelectedPriceIndex(index);
                }}
              />
            )}

            <Text adjustsFontSizeToFit numberOfLines={1} style={[{ color: 'white', fontSize: 80 }]}>
              ${formatMoney(selectedPrice.price, selectedPeriod === SubscriptionPeriod.Monthly)}
            </Text>

            <Animated.Text
              style={[
                Barlow.medium,
                {
                  color: 'white',
                  letterSpacing: 1.2,
                  marginBottom: 20,
                  opacity: perDurationAnim,
                  transform: [{ translateX: perDurationAnimTranslate }],
                },
              ]}>
              {selectedPeriod === SubscriptionPeriod.Monthly ? 'PER MONTH' : 'PER YEAR'}
            </Animated.Text>

            <Chip
              chipStyle={{
                backgroundColor: selectedPrice.savings ? '#E85F50' : '#499829',
                borderWidth: 0,
                marginBottom: 20,
              }}>
              <Text
                fontFamily="Barlow-Bold"
                style={[
                  {
                    color: 'white',
                    letterSpacing: 0.8,
                    textAlign: 'center',
                    minWidth: 70,
                  },
                ]}>
                {selectedPrice.savings ? `Save $${Math.round(selectedPrice.savings)}` : 'Popular'}
              </Text>
            </Chip>

            <Link
              style={{
                color: 'white',
                textDecorationLine: 'underline',
                marginBottom: 20,
              }}
              link="https://auslawconcierge.com.au/app/terms-and-conditions/">
              Terms &amp; Conditions
            </Link>

            {mustCancelSubscription ? (
              <TextButton
                text="CANCEL PLAN"
                textStyle={{
                  color: 'white',
                  textDecorationLine: 'underline',
                  fontSize: 18,
                  marginVertical: 10,
                  letterSpacing: 1,
                  fontFamily: 'Barlow',
                }}
                style={{ minHeight: 52 }}
                onPress={() => {
                  setBusy(true);
                  CancelSubscription(existingPlan?.subscriptionId!)
                    .then(res => {
                      const newProfile = res as Profile;
                      dispatch(setProfile(newProfile));
                      Alert.alert('Subscription Cancelled', 'Your subscription has been cancelled.');

                      if (orgListings?.length) {
                        orgListings.forEach(listing => {
                          updateDoc(ProfileDocument(listing.profileId!), {
                            parentProfileId: deleteField(),
                          });
                        });
                        dispatch(fetchOrgListings());
                      }

                      if (orgInvites?.length) {
                        orgInvites.forEach(invite => {
                          dispatch(cancelInvite(invite.id));
                        });
                      }
                    })
                    .catch(reason => {
                      //Todo
                      alert('Cancel Subscription Error:');
                      captureException(reason);
                    })
                    .finally(() => setBusy(false));
                }}
              />
            ) : (
              <PrimaryButton title="SELECT PLAN" inverted onPress={() => handlePayment()} />
            )}
          </View>
          <Elements stripe={stripe}>
            <CreditCardPaymentBox
              visible={showModalPaymentCreditCard}
              onClose={() => setShowModalPaymentCreditCard(false)}
              onPaymentSuccess={updatePaymentResult}
              paymentDetail={paymentDetail}
            />
          </Elements>
        </View>
      )}
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Subscription Exists</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            A subscription already exists for this account. Do you want to change?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <Button
            onClick={() => {
              setPaymentDetail({
                priceId: priceId,
                subscriptionPeriod: selectedPeriod,
                profileAmount: selectedPrice?.amount,
                isDoyles: userState.profile?.isDoyles,
              });
              setShowModalPaymentCreditCard(true);
              setShowDialog(false);
            }}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorDialog}
        onClose={() => setErrorDialog(null)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{errorDialog}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setErrorDialog(null);
            }}
            autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {busy && <FullscreenActivityIndicator />}
    </LoginBase>
  );
};

export default PaymentScreen;
