import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
  Stack,
  IconButton,
  Modal,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { Colour } from '../../../styles/style';
import { useCommunicatemutations } from '../../../api/mutations';
import { queryClient } from '../../../api/queryClient';
import { GET_ALL_COMMUNITIES } from '../../../constants/queryKeys';
import { useLinkTo } from '@react-navigation/native';

interface User {
  id: number;
  username: string;
}

interface CommunityData {
  name: string;
  description: string;
  image: string | null;
  admins: string[];
  members: string[];
  imageFile: File | null;
}

interface CommunityModalProps {
  open: boolean;
  onClose: () => void;
  communityData: CommunityData;
  setCommunityData: React.Dispatch<React.SetStateAction<CommunityData>>;
  adminsList?: User[];
  userList?: User[];
  selectedAdmins: string[];
  setSelectedAdmins: React.Dispatch<React.SetStateAction<string[]>>;
  selectedMembers: string[];
  setSelectedMembers: React.Dispatch<React.SetStateAction<string[]>>;
  onSave: () => void;
  edit?: boolean;
  errorCreateCommunity?: any;
}

const CommunityModal: React.FC<CommunityModalProps> = ({
  open,
  onClose,
  communityData,
  setCommunityData,
  adminsList,
  userList,
  selectedAdmins,
  setSelectedAdmins,
  selectedMembers,
  setSelectedMembers,
  onSave,
  edit,
  errorCreateCommunity,
}) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const { deleteCommunity } = useCommunicatemutations();
  const linkTo = useLinkTo();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCommunityData(prev => ({ ...prev, [name]: value }));
  };

  const communityId = localStorage.getItem('idChat') || 0;

  const handleDeleteCommunityId = (id: number) => {
    deleteCommunity(id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [GET_ALL_COMMUNITIES] });
        setOpenDeleteConfirm(false);
        localStorage.setItem('idChat', '0');
        linkTo('/community?id=0');
        onClose();
      },
      onError: err => {
        console.log('Delete error:', err);
      },
    });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setCommunityData(prev => ({
        ...prev,
        image: URL.createObjectURL(file),
        imageFile: file,
      }));
    }
  };

  const removeImage = () => {
    setCommunityData(prev => ({ ...prev, image: null, imageFile: null }));
  };

  const handleSelectAdminsChange = (e: SelectChangeEvent<string[]>) => {
    setSelectedAdmins(e.target.value as string[]);
  };

  const handleMembersChange = (_: any, newValue: User[]) => {
    setSelectedMembers(newValue.map(member => String(member.id)));
  };

  const handleDeleteMember = (memberToDelete: User) => {
    setSelectedMembers(members => members.filter(memberId => memberId !== String(memberToDelete.id)));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius: 2,
          fontFamily: 'Barlow',
        }}>
        <h2 style={{ margin: 0, textAlign: 'center' }}> {edit ? 'Edit Community' : 'Create Community'}</h2>
        {!edit && (
          <>
            {communityData.image ? (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <img
                  src={communityData.image}
                  alt="Community Preview"
                  style={{ width: 100, height: 100, borderRadius: 8, objectFit: 'cover' }}
                />
                <IconButton onClick={removeImage} color="error" aria-label="remove image" size="small" sx={{ ml: 1 }}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            ) : (
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 1,
                  borderColor: Colour.LightBlue,
                }}>
                Upload Image
                <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
              </Button>
            )}
          </>
        )}
        <TextField label="Name" name="name" value={communityData.name} onChange={handleInputChange} fullWidth />
        <TextField
          label="Description"
          name="description"
          value={communityData.description}
          onChange={handleInputChange}
          fullWidth
          multiline
          rows={3}
        />

        {!edit && (
          <FormControl fullWidth>
            <InputLabel>Admins</InputLabel>
            <Select label="Admins" value={selectedAdmins} onChange={handleSelectAdminsChange} multiple>
              {adminsList &&
                adminsList.map(admin => (
                  <MenuItem key={admin.id} value={String(admin.id)}>
                    {admin.username}
                  </MenuItem>
                ))}
            </Select>
            {errorCreateCommunity && errorCreateCommunity.errorAdmins.length > 0 && (
              <p style={{ margin: 0, color: 'red', paddingTop: 10, fontSize: 10 }}>
                {errorCreateCommunity.errorAdmins}
              </p>
            )}
          </FormControl>
        )}

        {userList && !edit && (
          <>
            <Autocomplete
              multiple
              options={userList}
              getOptionLabel={option => option.username}
              onChange={handleMembersChange}
              renderTags={() => null}
              renderInput={params => (
                <TextField {...params} variant="outlined" label="Add Members" placeholder="Select Members" />
              )}
            />

            {errorCreateCommunity && errorCreateCommunity.errorMembers.length > 0 && (
              <p style={{ margin: 0, color: 'red', fontSize: 10 }}>{errorCreateCommunity.errorMembers}</p>
            )}
            <Stack direction="row" spacing={1} flexWrap="wrap" sx={{ mt: 1 }}>
              {selectedMembers &&
                selectedMembers
                  .map(memberId => userList.find(user => String(user.id) === memberId))
                  .filter(Boolean)
                  .map(member => (
                    <Chip
                      key={member!.username}
                      label={member!.username}
                      onDelete={() => handleDeleteMember(member!)}
                    />
                  ))}
            </Stack>
          </>
        )}
        {errorCreateCommunity && errorCreateCommunity.errorSubmit.length > 0 && (
          <p style={{ margin: 0, color: 'red', fontSize: 10 }}>{errorCreateCommunity.errorSubmit}</p>
        )}
        <Button onClick={onSave} sx={{ mt: 2, backgroundColor: Colour.LightBlue }}>
          {edit ? 'Save Community' : 'Create Community'}
        </Button>

        {edit && (
          <Button sx={{ color: Colour.DeclineRed }} onClick={() => setOpenDeleteConfirm(true)}>
            Delete Community
          </Button>
        )}

        <Dialog open={openDeleteConfirm} onClose={() => {}}>
          <DialogTitle>Are you sure you want to delete this community?</DialogTitle>
          <DialogActions>
            <Button onClick={() => setOpenDeleteConfirm(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleDeleteCommunityId(+communityId)} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Modal>
  );
};

export default CommunityModal;
