import React, { useEffect, useState } from 'react';
import imgPrivateChat from '../../assets/images/imgPrivateChat.svg';
import imgCommunity from '../../assets/images/communityImg.svg';
import { PrimaryButton } from '../../components/Button';
import { useLinkTo } from '@react-navigation/native';
import { useMediaQuery } from '@mui/material';
import { useGetAllCommunitiesQuery } from '../../api/queries';

function ChooseCommunity() {
  const linkTo = useLinkTo();
  const isMobile = useMediaQuery('(max-width:900px)');

  const { data: communityList } = useGetAllCommunitiesQuery();
  const storedChatId = localStorage.getItem('idChat');

  useEffect(() => {
    if (communityList && communityList.length > 0) {
      localStorage.setItem('idChat', JSON.stringify(communityList[0].id));
    }
  }, [communityList]);

  return (
    <div
      style={{
        width: '100%',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        fontFamily: 'Barlow',
        justifyContent: isMobile ? 'center' :'normal'
      }}>
      <div
        style={{
          width: isMobile ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isMobile ? '20px 0' : '0',
        }}>
        <img src={imgPrivateChat} style={{ width: isMobile ? '60%' : '80%', height: isMobile ? 'auto' : 'auto' }} />
        <p
          style={{
            color: 'white',
            fontSize: isMobile ? 16 : 18,
            width: isMobile ? '80%' : '70%',
            textAlign: 'center',
            marginTop: isMobile ? 10 : 0,
            height: '100px'
          }}>
          Create private one-on-one chats or join group chats to connect, collaborate, and share updates with others.
        </p>
        <PrimaryButton
          containerStyle={{
            marginTop: 10,
            paddingHorizontal: isMobile ? 20 : 30,
            width: isMobile ? '60%' : '40%',
          }}
          title="Join Chat"
          onPress={() => linkTo('/chat')}
          inverted
        />
      </div>
      <div
        style={{
          width: isMobile ? '100%' : '50%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: isMobile ? '20px 0' : '0',
        }}>
        <img src={imgCommunity} style={{ width: isMobile ? '60%' : '80%', height: isMobile ? 'auto' : 'auto' }} />
        <p
          style={{
            color: 'white',
            fontSize: isMobile ? 16 : 18,
            width: isMobile ? '80%' : '70%',
            textAlign: 'center',
            marginTop: isMobile ? 10 : 0,
            height: '100px'
          }}>
                  Join different communities where the latest news and updates from all professions can be discussed. These are
          spaces for sharing information, finding work, and staying up to date with events around Australia.
        </p>
        <PrimaryButton
          containerStyle={{
            marginTop: 10,
            paddingHorizontal: isMobile ? 20 : 30,
            width: isMobile ? '60%' : '40%',
          }}
          title="Join Community"
          onPress={() => linkTo(`/community?id=${storedChatId}`)}
          inverted
        />
      </div>
    </div>
  );
}

export default ChooseCommunity;
