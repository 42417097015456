import KeyboardBackspaceTwoToneIcon from '@mui/icons-material/KeyboardBackspaceTwoTone';
import { CircularProgress } from '@mui/material';
import { useLinkTo } from '@react-navigation/native';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { View } from 'react-native';
import { ISODateString } from 'react-native-calendar-events';
import { useDispatch, useSelector } from 'react-redux';
import { useGetEventByCalendarQuery } from '../../api/queries';
import { useGetUserByFirebaseIdQuery } from '../../api/queries/useGetUserByFirebaseIdQuery';
import socket from '../../api/socket/socket';
import { PrimaryButton } from '../../components/Button';
import { monday, saturday, selectedTimesHumanReadable } from '../../components/Calendar/CalendarHelper';
import MonthStrip from '../../components/Calendar/MonthStrip';
import WeekStrip from '../../components/Calendar/WeekStrip';
import { useSafeBottomPadding } from '../../components/Hooks';
import Text from '../../components/Text';
import { CalendarScreenProps } from '../../navigators/Types';
import { setDateRange } from '../../redux/Search';
import { userStateSelector } from '../../redux/Store';
import { logAnalyticsEvent } from '../../services/Firebase';
import style, { Colour } from '../../styles/style';
import { popSafely } from '../../tools/NavHelpers';
import LoginBase from '../auth/LoginBase';
import CalendarAccessModal from './CalendarAccessModal';

interface CalendarEvent {
  id: string;
  grant_id: string;
  when: {
    startTime?: number;
    start_time?: number;
    endTime?: number;
    end_time?: number;
  };
}

const CalendarScreen = ({ route, navigation }: CalendarScreenProps) => {
  const linkTo = useLinkTo();
  const dispatch = useDispatch();
  const userState = useSelector(userStateSelector);
  const listing = route?.params;
  const [date, setDate] = useState<moment.Moment>(() => {
    const currentDate = moment().startOf('day');
    return (currentDate.day() < saturday ? currentDate : moment(currentDate).startOf('day').add(1, 'week')).isoWeekday(
      monday,
    );
  });
  const [selectedTimes, setSelectedTimes] = useState<ISODateString[]>([]);
  const [dataCarrentEvents, setDataCarrentEvents] = useState<CalendarEvent[]>([]);
  const { data: userData, isFetching: isLoadingUserData } = useGetUserByFirebaseIdQuery(listing?.profileId as string);
  const [modalVisible, setModalVisible] = useState(false);
  const [errorModalVisible, setErrorModalVisible] = useState(false);

  const convertToISO = (timestamp?: number): string | null => {
    if (timestamp === undefined || timestamp === null || isNaN(timestamp)) {
      return null;
    }
    return new Date(timestamp * 1000).toISOString();
  };

  const calendarId = userData?.calendarId;
  const grantId = userData?.grantId;
  const {
    data: eventCalendarData,
    isFetching,
    error: eventCalendarRequestError,
  } = useGetEventByCalendarQuery(calendarId, grantId);

  const userIsTheOwner = useMemo(
    () => userState.auth?.userId == listing?.profileId,
    [listing?.profileId, userState.auth?.userId],
  );
  const changeDate = (val: moment.Moment) => setDate(val);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const filteredEvents = useMemo(() => {
    if (Array.isArray(dataCarrentEvents)) {
      const result = dataCarrentEvents
        .map(event => {
          const startDate = convertToISO(event.when.startTime ?? event.when.start_time) ?? '';
          const endDate = convertToISO(event.when.endTime ?? event.when.end_time) ?? '';
          return startDate && endDate ? { startDate, endDate } : null;
        })
        .filter(Boolean) as { startDate: string; endDate: string }[];
      const startDate = date.toISOString();
      const endDate = moment(date).add(6, 'day').toISOString();
      return result.filter(event => event.startDate >= startDate && event.endDate <= endDate);
    }
    return [];
  }, [date, dataCarrentEvents]);

  useEffect(() => {
    if (eventCalendarData) {
      setDataCarrentEvents(eventCalendarData.data);
    }
  }, [eventCalendarData]);

  const handleEventCreated = (eventData: { object: CalendarEvent }) => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents => [...prevEvents, eventData.object]);
    }
  };
  const handleEventUpdated = (eventData: { object: CalendarEvent }) => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents =>
        prevEvents.map(event => (event.id === eventData.object.id ? { ...event, ...eventData.object } : event)),
      );
    }
  };
  const handleEventDeleted = (eventData: { object: CalendarEvent }) => {
    if (eventData.object.grant_id === grantId) {
      setDataCarrentEvents(prevEvents => prevEvents.filter(event => event.id !== eventData.object.id));
    }
  };
  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
    socket.on('calendarEventCreated', eventData => {
      console.log(eventData, 'eventData');
      handleEventCreated(eventData);
    });
    socket.on('calendarEventUpdated', event => handleEventUpdated(event));
    socket.on('calendarEventDeleted', event => handleEventDeleted(event));
    return () => {
      socket.off('calendarEventCreated', handleEventCreated);
      socket.off('calendarEventUpdated', handleEventUpdated);
      socket.off('calendarEventDeleted', handleEventDeleted);
    };
  }, []);

  useEffect(() => {
    if (!isLoadingUserData && (!calendarId || !grantId)) {
      setModalVisible(true);
    }
  }, [calendarId, grantId]);

  useEffect(() => {
    if (eventCalendarRequestError) {
      console.error(eventCalendarRequestError);
      setErrorModalVisible(true);
    }
  }, [eventCalendarRequestError]);

  if (isLoadingUserData || isFetching) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress sx={{ color: 'white' }} />
      </View>
    );
  }
  return (
    <LoginBase>
      <View
        onClick={() => history.back()}
        style={{
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100vw',
          gap: '5px',
          marginLeft: '20px',
        }}>
        <KeyboardBackspaceTwoToneIcon sx={{ color: Colour.White }} /> <Text style={{ color: 'white' }}>BACK</Text>
      </View>
      <View style={[style.footer, { maxHeight: '80vh' }]}>
        <MonthStrip date={date} setDate={changeDate} />
        <WeekStrip date={date} events={filteredEvents} onChangeValue={setSelectedTimes} />
        <View
          style={[
            style.rowCenter,
            {
              flex: 0,
              paddingHorizontal: 20,
              paddingBottom: useSafeBottomPadding(),
              minHeight: 'auto',
            },
          ]}>
          {!listing?.id?.length && (
            <Text
              style={[
                {
                  fontSize: 22,
                  color: Colour.Blue,
                  marginVertical: 10,
                  letterSpacing: 1.1,
                },
              ]}>
              {selectedTimesHumanReadable(selectedTimes, filteredEvents !== undefined ? 1 : 4)}
            </Text>
          )}
          <PrimaryButton
            title={listing?.id ? 'MESSAGE' : 'SELECT DATES'}
            disabled={selectedTimes.length === 0 || userIsTheOwner}
            onPress={() => {
              logAnalyticsEvent('calendar_dates_selected', {
                from: selectedTimes[0],
                to: selectedTimes.length > 1 ? selectedTimes[selectedTimes.length - 1] : undefined,
              });
              if (listing?.id) {
                const toDate =
                  selectedTimes.length === 1
                    ? moment(selectedTimes[0]).add(1, 'hour').toISOString()
                    : moment(selectedTimes[selectedTimes.length - 1])
                        .add(1, 'hour')
                        .toISOString();
                linkTo({
                  screen: 'SendMessage',
                  params: {
                    id: listing.id,
                    fromDate: selectedTimes[0],
                    toDate,
                  },
                });
              } else {
                dispatch(setDateRange(selectedTimes));
                popSafely(navigation);
              }
            }}
          />
        </View>
      </View>
      <CalendarAccessModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        title="Calendar Access Restricted"
        message="You do not have the necessary permissions to access the calendar. Please contact us."
        buttonText="Contact Support"
      />
      <CalendarAccessModal
        visible={errorModalVisible}
        onClose={() => setErrorModalVisible(false)}
        title="Calendar Access Restricted"
        message="Access to this calendar is restricted. Please check your settings or contact support for assistance."
        buttonText="Contact Support"
      />
    </LoginBase>
  );
};
export default CalendarScreen;
