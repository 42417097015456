import Chip from "@mui/material/Chip"
import FormControl from "@mui/material/FormControl"
import Input from "@mui/material/Input"
import { makeStyles } from "@mui/material";
import React, { useEffect, useState } from "react";


export default function MultiTextSelectInput({values, setValues, currValue, setCurrValue}) {

    const handleKeyUp = (e) => {
        if (e.keyCode == 32 || e.keyCode === 13) {
            setValues((oldState) => [...oldState, e.target.value]);
            setCurrValue("");
        }
    };

    const handleChange = (e) => {
        setCurrValue(e.target.value);
    };

    const handleDelete = (item, index) => {
        let arr = [...values]
        arr.splice(index, 1)
        setValues(arr)
    }

    return (
        <div className="App">
            <FormControl style={{width: '100%'}}>
                <div className={"container"}>
                    {values.map((item, index) => (
                        <Chip size="small" onDelete={() => handleDelete(item, index)} label={item} />
                    ))}
                </div>
                <Input
                    value={currValue}
                    onChange={handleChange}
                    onKeyDown={handleKeyUp}
                />
            </FormControl>
        </div>
    );
}