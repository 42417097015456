import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_INVITATIONS_BY_INVITEE_ID } from '../../constants/queryKeys';

export const useGetInvitationsByInviteeIdQuery = (idUser: number) => {
  return useQuery({
    queryKey: [GET_INVITATIONS_BY_INVITEE_ID, idUser],
    queryFn: () => apiHandlers.invitationCommunity.getInvitationsByInviteeId(idUser).then(data => data.data),
  });
};
