import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useChatmutations = () => {
  const { mutate: chatMutate } = useMutation({
    mutationFn: apiHandlers.chats.createChat,
  });

  const { mutate: chatEditMutate } = useMutation({
    mutationFn: apiHandlers.chats.editChatById,
  });

  const { mutate: chatDelete } = useMutation({
    mutationFn: apiHandlers.chats.deleteChatById,
  });

  return {
    chatMutate,
    chatDelete,
    chatEditMutate,
  };
};
