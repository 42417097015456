import React, { useEffect, useState } from 'react';
import ContentCommunity from './contentComponents/ContentCommunity';
import SideBarCommunity from './sideBarCommunity/SideBarCommunity';
import { useGetAllCommunitiesQuery } from '../../api/queries/useGetAllCommunitiesQuery';
import { IconButton, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useGetAllUserAdmin, useGetInvitationsByInviteeIdQuery } from '../../api/queries';
import { queryClient } from '../../api/queryClient';
import { GET_CHAT_BY_CHATID, GET_INVITATIONS_BY_INVITEE_ID } from '../../constants/queryKeys';
import { useSendInviteCommunityMutation } from '../../api/mutations';

function CommunityMainBlock() {
  const { data: communityList } = useGetAllCommunitiesQuery();
  const [hasInvite, setHasInvite] = useState(false);
  const [ibChat, setIdChat] = useState<number>();
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const isMobile = useMediaQuery('(max-width:990px)');
  const storedUserId = parseInt(localStorage.getItem('userId') || '', 10);
  const { data: userAdminList } = useGetAllUserAdmin();
  const { data: listIvitations } = useGetInvitationsByInviteeIdQuery(storedUserId ? +storedUserId : 0);
  const userId = localStorage.getItem('userId') || '0';
  const communityId = localStorage.getItem('idChat') || '0';
  const { updateInviteStatus } = useSendInviteCommunityMutation();

  const handleAcceptInvite = (id: number) => {
    if (userId && communityId && listIvitations) {
      const invitation = listIvitations.find(
        invite => invite.inviteeId === Number(userId) && invite.communityId === Number(id),
      );

      if (invitation) {
        updateInviteStatus(
          {
            statusId: invitation.id,
            data: { status: 'accepted' },
          },
          {
            onSuccess: () => {
              setHasInvite(false);
              queryClient.invalidateQueries({ queryKey: [GET_INVITATIONS_BY_INVITEE_ID, +userId] });
              queryClient.invalidateQueries({ queryKey: [GET_CHAT_BY_CHATID, +communityId] });
            },
          },
        );
      } else {
        console.error('Invitation not found or already accepted/declined');
      }
    } else {
      console.error('Missing userId or communityId or invitations');
    }
  };

  const handleDeclineInvite = (id: number) => {
    if (userId && communityId && listIvitations) {
      const invitation = listIvitations.find(
        invite => invite.inviteeId === Number(userId) && invite.communityId === Number(id),
      );

      if (invitation) {
        updateInviteStatus(
          {
            statusId: invitation.id,
            data: { status: 'declined' },
          },
          {
            onSuccess: () => {
              setHasInvite(false);
              queryClient.invalidateQueries({ queryKey: [GET_INVITATIONS_BY_INVITEE_ID, +userId] });
            },
          },
        );
      } else {
        console.error('Invitation not found or already accepted/declined');
      }
    } else {
      console.error('Missing userId or communityId or invitations');
    }
  };

  const isPermissionAdmin = userAdminList?.some(admin => admin.id === +userId);

  console.log(isPermissionAdmin, 'isPermissionAdmin');

  useEffect(() => {
    if (communityList && communityList.length > 0) {
      setIdChat(communityList[0].id);
    }
  }, [communityList]);

  const toggleSidebar = () => setSidebarVisible(!isSidebarVisible);

  useEffect(() => {
    if (!userId || !communityId) {
      console.error('Missing userId or communityId in localStorage');
      return;
    }

    const userInvites =
      listIvitations?.filter(
        invite => invite.inviteeId === Number(userId) && invite.communityId === Number(communityId),
      ) || [];

    if (userInvites.length > 0 && userInvites.some(invite => invite.status === 'pending')) {
      setHasInvite(true);
    } else {
      setHasInvite(false);
    }
  }, [listIvitations, communityId, userId]);

  return (
    <div style={{ display: 'flex', width: '100%', minHeight: '100vh', background: 'white', fontFamily: 'Barlow' }}>
      {isMobile && (
        <IconButton
          onClick={toggleSidebar}
          style={{
            position: 'absolute',
            top: 3,
            left: 10,
            zIndex: 100,
            backgroundColor: '#007bff',
            color: 'white',
            padding: '10px',
            borderRadius: '50%',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            boxSizing: 'border-box',
          }}>
          {isSidebarVisible ? <CloseIcon /> : <KeyboardDoubleArrowLeftIcon />}
        </IconButton>
      )}
      <SideBarCommunity
        isPermissionAdmin={isPermissionAdmin}
        setIdChat={setIdChat}
        isVisible={isSidebarVisible}
        toggleSidebar={toggleSidebar}
        acceptInvite={handleAcceptInvite}
        declineInvite={handleDeclineInvite}
      />

      {communityId !== '0' ? (
        <ContentCommunity
          isPermissionAdmin={isPermissionAdmin}
          hasInvite={hasInvite}
          acceptInvite={handleAcceptInvite}
          declineInvite={handleDeclineInvite}
        />
      ) : (
        <div style={styles.emptyCommunityContainer}>
          <h2 style={styles.emptyCommunityText}>Please select a community to view posts.</h2>
        </div>
      )}
    </div>
  );
}

const styles = {
  emptyCommunityContainer: {
    width: '100%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  emptyCommunityText: {
    color: '#888',
    fontSize: '24px',
    textAlign: 'center',
  },
};

export default CommunityMainBlock;
