import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useCommunicatemutations = () => {
  const { mutate: createCommunity } = useMutation({
    mutationFn: apiHandlers.communities.createCommunity,
  });
  const { mutate: deleteCommunity } = useMutation({
    mutationFn: apiHandlers.communities.deleteCommunity,
  });

  const {mutate: addUserCommunity} = useMutation({
    mutationFn: apiHandlers.communities.addUserCommunity
  })

  const {mutate: deleteUserCommunity} = useMutation({
    mutationFn: apiHandlers.communities.deleteUserCommunity
  })

  const {mutate: updateCommunity} = useMutation({
    mutationFn: apiHandlers.communities.updateCommunity
  })

  return {
    createCommunity,
    deleteCommunity,
    addUserCommunity,
    deleteUserCommunity,
    updateCommunity
  };
};
