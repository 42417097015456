import React from 'react';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
  searchValue: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchValue, setSearchValue }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        width: '90%',
      }}>
      <SearchIcon
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          fontSize: 18,
          color: '#888',
        }}
      />
      <input
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        style={{
          width: '100%',
          padding: '10px 35px 10px 40px',
          borderRadius: '25px',
          border: '1px solid #ccc',
          backgroundColor: '#f9f9f9',
          outline: 'none',
          color: '#333',
          transition: 'all 0.3s ease',
        }}
        onFocus={e => {
          e.currentTarget.style.border = '1px solid #007bff';
          e.currentTarget.style.boxShadow = '0 0 5px rgba(0, 123, 255, 0.3)';
        }}
        onBlur={e => {
          e.currentTarget.style.border = '1px solid #ccc';
          e.currentTarget.style.boxShadow = 'none';
        }}
      />
    </div>
  );
};

export default SearchBar;
