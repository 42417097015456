import React, { createContext, createRef, useEffect, useState } from 'react';
import * as Sentry from "@sentry/react";
import { ImageBackground, StatusBar, View } from 'react-native';
import { Provider } from 'react-redux';
import AppNavigator from './src/navigators/AppNavigator';
import { theme } from './src/styles/Theme';
import '@stripe/stripe-js'; //Importing as a side effect, best practice according to https://github.com/stripe/stripe-js
import store from './src/redux/Store';
import { ThemeProvider } from 'react-native-elements';
import bg from './src/assets/images/imgBg@3x.png'
import { DefaultTheme, NavigationContainer } from '@react-navigation/native';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import LoadingScreen from './src/screens/LoadingScreen';
import LoginBase from './src/screens/auth/LoginBase';
import { logScreenView } from './src/services/Firebase';
import config from './src/config.json';
import {
  QueryClientProvider,
} from '@tanstack/react-query'
import { queryClient } from './src/api/queryClient';


const linking = {
  prefixes: [
  ],
  config: {
    screens: {
      Welcome: 'welcome/:inviteId?',
      ProfileList: 'profilelist',
      Menu: 'menu',
      SearchNavigator: 'search',
      RefineSearch: 'refinesearch',
      ChooseCalendar: 'choosecalendar',
      Calendar: 'calendar',
      CreateListing: 'createlisting',
      ViewListing: 'listing',
      ViewPersonalProfile: 'profile',
      ConfirmAccountDeletionScreen: 'deleteprofile',
      EditPersonalProfile: 'editprofile',
      Payment: 'payment',
      ViewOrganisation: 'organisation',
      EditOrganisation: 'editorganisation',
      SendMessage: 'sendmessage',
      MessageSent: 'messagesent',
      AcceptInvitation: 'acceptinvite',
      MergeListings: 'mergelistings/:code',
      Help: 'help',
      Login: 'login',
      Forgot: 'forgot',
      PasswordReset: 'passwordreset',
      PasswordSent: 'passwordsent',
      AccountType: 'accounttype',
      Signup: 'signup',
      AccountRemovedScreen: 'accountremoved',
      signupComplete: 'signupcomplete',
      Version: 'version',
      Loading: '*',
      Chat: 'chat',
      ChatReview: 'chatreview',
      Community: 'community',
      ChooseCommunity: 'choosecommunity'
    },
  },
};
export const MenuContext = createContext();

const navTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: 'transparent'
  }
}

const routeNameRef = createRef();
const navigationRef = createRef();

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: ["localhost", /^https:\/\/web\.auslawconcierge\.com\.au/, /^https:\/\/dev-auslaw-conierge\.web\.app/,],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const App = ({}) => {

  const [menuContextValue, setMenuContextValue] = useState(false);
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <StatusBar barStyle="default" translucent={true} backgroundColor="transparent" />
        <ThemeProvider theme={theme}>
          <MenuContext.Provider value={[menuContextValue, setMenuContextValue]}>
            <View style={{ width: '100%', height: '100%', flex: 1 }}>
              <ImageBackground id={'img-bg'} source={bg} style={{ display: 'flex', height: '100%', width: '100%' }} resizeMode="stretch">
                <NavigationContainer
                  ref={navigationRef}
                  onReady={() => routeNameRef.current = navigationRef.current.getCurrentRoute().name}
                  onStateChange={() => {
                    const previousRouteName = routeNameRef.current
                    const currentRouteName = navigationRef.current.getCurrentRoute().name
                
                    if (previousRouteName !== currentRouteName) {
                      // Do something here with it
                      logScreenView(currentRouteName)
                    }
                
                    // Save the current route name for later comparision
                    routeNameRef.current = currentRouteName
                  }}
                  fallback={LoadingScreen}
                  linking={linking}
                  theme={navTheme}
                  documentTitle={{
                    enabled: false,
                  }}
                >
                  <AppNavigator></AppNavigator>
                </NavigationContainer>
              </ImageBackground>
            </View>
          </MenuContext.Provider>
        </ThemeProvider>
      </LocalizationProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
