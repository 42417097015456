import axios, { AxiosRequestConfig } from 'axios';

import { AxiosResponseSuccess } from './types';

function createHttpClient() {
  // const baseUrl ='http://10.0.2.2:3000';
  const baseUrl = 'https://auslaw-chat.onrender.com';
  return axios.create({
    baseURL: baseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export const httpClient = createHttpClient();

export async function makeHttpRequest<SuccessPayload>(
  config: AxiosRequestConfig,
): AxiosResponseSuccess<SuccessPayload> {
  const headersToSend: AxiosRequestConfig['headers'] = { ...config.headers };
  //   const currentToken = localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);

  //   headersToSend['Authorization'] = `Bearer ${currentToken}`;
  return httpClient.request<SuccessPayload>({
    ...config,
    headers: {
      ...headersToSend,
    },
  });
}
