import React, { useState, useEffect } from 'react';
import ChatSideBar from './ChatSideBar';
import ChatComponent from './ChatMessage';
import { useGetAllChatByIdQuery } from '../../api/queries';
import { IconButton } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

function ChatMainBlock() {
  const [selectedChatId, setSelectedChatId] = useState<number | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [isSidebarVisible, setSidebarVisible] = useState(true);

  const isMobile = useMediaQuery('(max-width:900px)'); // Перевірка на мобільний пристрій
  const { data: chatList, isLoading, error } = useGetAllChatByIdQuery(userId);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(parseInt(storedUserId, 10));
    }
  }, []);

  const handleChatClick = (chatId: number) => {
    setSelectedChatId(chatId);
    if (isMobile) setSidebarVisible(false); // Закриваємо сайдбар на мобільних при виборі чату
  };

  const toggleSidebar = () => setSidebarVisible(!isSidebarVisible);

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      {isMobile && (
        <IconButton
          onClick={toggleSidebar}
          style={{
            position: 'absolute',
            top: 3,
            left: 10,
            zIndex: 900,
            backgroundColor: '#1976d2',
            color: 'white',
            padding: 4,
            borderRadius: '50%',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          }}>
          {isSidebarVisible ? '' : <KeyboardDoubleArrowLeftIcon />}
        </IconButton>
      )}

      {(isMobile && isSidebarVisible) || !isMobile ? (
        <ChatSideBar
          userId={userId}
          onChatClick={handleChatClick}
          isVisible={isSidebarVisible}
          toggleSidebar={toggleSidebar}
        />
      ) : null}

      {selectedChatId !== null ? (
        <ChatComponent
          isVisible={isSidebarVisible}
          chatId={selectedChatId}
          userId={userId || 0}
          onChatClick={handleChatClick}
        />
      ) : !isLoading ? (
        <div style={styles.emptyChatContainer}>
          {!isLoading && (
            <h2 style={styles.emptyChatText}>Please select or create the chat to start the conversation.</h2>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

const styles = {
  emptyChatContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f1f1f1',
  },
  emptyChatText: {
    fontFamily: 'Arial, sans-serif',
    color: '#888',
    fontSize: '24px',
    textAlign: 'center',
  },
};

export default ChatMainBlock;
