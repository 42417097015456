import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_ALL_USERS_ADMIN } from '../../constants/queryKeys';

export const useGetAllUserAdmin = () => {
  return useQuery({
    queryKey: [GET_ALL_USERS_ADMIN],
    queryFn: () => apiHandlers.admins.getAllUserAdmin().then(data => data.data),
  });
};
