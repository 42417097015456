import React, { useState } from 'react';
import { Button, useMediaQuery } from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReplyIcon from '@mui/icons-material/Reply';
import InviteModal from './InviteModal';

interface CommunityHeaderProps {
  img: string;
  avatarimg: string;
  Colour: { DarkGrey: string; Blue: string };
  nameCommunity?: string;
  joinCommunity: any;
  leaveCommunity: any;
  isJoin?: boolean;
  toggleModalEdit?: any;
  isPermissionAdmin?: boolean;
  memberCommunity?: string[];
  hasInvite?: boolean;
  acceptInvite: (id: number) => void;
  declineInvite: (id: number) => void;
  isAdmin: boolean;
}

const CommunityHeader: React.FC<CommunityHeaderProps> = ({
  img,
  avatarimg,
  Colour,
  nameCommunity,
  joinCommunity,
  leaveCommunity,
  isJoin,
  toggleModalEdit,
  isPermissionAdmin,
  memberCommunity,
  hasInvite,
  acceptInvite,
  declineInvite,
  isAdmin,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const communityId = localStorage.getItem('idChat') || 0;

  const handleInviteClick = () => {
    setInviteModalOpen(true);
  };

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}>
      <img src={img} style={{ width: '100%', height: '100px', position: 'absolute', zIndex: 0 }} />
      <div
        style={{
          zIndex: 1,
          position: 'relative',
          paddingTop: '6%',
          paddingLeft: '1%',
          display: 'flex',
          alignItems: 'flex-end',
        }}>
        <img
          src={avatarimg}
          style={{ width: 100, height: 100, border: '2px solid white', borderRadius: '50%', objectFit: 'cover' }}
        />
        <p
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: Colour.DarkGrey,
            margin: 0,
            display: 'block',
            marginBottom: 5,
          }}>
          {nameCommunity}
        </p>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: isMobile ? 4 : 10,
          zIndex: 1,
          position: 'relative',
          marginTop: '10%',
          marginRight: '3%',
        }}>
        {hasInvite ? (
          <>
            <Button
              onClick={() => acceptInvite(+communityId)}
              style={{
                width: isMobile ? 90 : 120,
                height: isMobile ? 32 : 40,
                backgroundColor: '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: 6,
                cursor: 'pointer',
                fontSize: isMobile ? 12 : 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? '0 8px' : '0 15px',
              }}>
              Accept
            </Button>

            <Button
              onClick={() => declineInvite(+communityId)}
              style={{
                width: isMobile ? 90 : 120,
                height: isMobile ? 32 : 40,
                backgroundColor: '#FF6B6B',
                color: 'white',
                border: 'none',
                borderRadius: 6,
                cursor: 'pointer',
                fontSize: isMobile ? 12 : 16,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: isMobile ? '0 8px' : '0 15px',
              }}>
              Decline
            </Button>
          </>
        ) : (
          <>
            {!isAdmin && !isPermissionAdmin && (
              <Button
                onClick={() => (isJoin ? leaveCommunity() : joinCommunity())}
                style={{
                  width: isMobile ? 90 : 120,
                  height: isMobile ? 32 : 40,
                  backgroundColor: isJoin ? '#FF6B6B' : '#4CAF50',
                  color: 'white',
                  border: 'none',
                  borderRadius: 6,
                  cursor: 'pointer',
                  fontSize: isMobile ? 12 : 16,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: isMobile ? '0 8px' : '0 15px',
                }}>
                {isJoin ? (
                  <ExitToAppIcon style={{ marginRight: 6, fontSize: isMobile ? 16 : 20 }} />
                ) : (
                  <PersonAddIcon style={{ marginRight: 6, fontSize: isMobile ? 16 : 20 }} />
                )}
                {!isJoin ? 'Join' : 'Leave'}
              </Button>
            )}

            {(isAdmin || isPermissionAdmin) && (
              <>
                <Button
                  onClick={toggleModalEdit}
                  style={{
                    width: 90,
                    height: isMobile ? 32 : 40,
                    backgroundColor: 'rgb(49, 17, 90)',
                    color: 'white',
                    border: 'none',
                    borderRadius: 6,
                    cursor: 'pointer',
                    fontSize: isMobile ? 12 : 16,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? '0 8px' : '0 15px',
                  }}>
                  Edit
                </Button>
                <Button
                  onClick={handleInviteClick}
                  style={{
                    backgroundColor: 'white',
                    color: Colour.Blue,
                    border: `2px solid ${Colour.Blue}`,
                    borderRadius: 6,
                    cursor: 'pointer',
                    fontSize: isMobile ? 12 : 16,
                    width: isMobile ? 90 : 120,
                    height: isMobile ? 32 : 40,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: isMobile ? '0 8px' : '0 15px',
                  }}>
                  <span style={{ marginRight: 6 }}>Invite</span>
                  <ReplyIcon style={{ fontSize: isMobile ? 16 : 20 }} />
                </Button>
              </>
            )}
          </>
        )}
      </div>

      <InviteModal
        inviteModalOpen={inviteModalOpen}
        setInviteModalOpen={setInviteModalOpen}
        memberCommunity={memberCommunity}
      />
    </div>
  );
};

export default CommunityHeader;
