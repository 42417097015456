import { useQuery } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';
import { GET_INVITATIONS_BY_COMMUNITY_ID } from '../../constants/queryKeys';

export const useGetInvitationsByCommunityIdQuery = (idCommunity: number) => {
  return useQuery({
    queryKey: [GET_INVITATIONS_BY_COMMUNITY_ID, idCommunity],
    queryFn: () => apiHandlers.invitationCommunity.getInvitationsByCommunityId(idCommunity).then(data => data.data),
  });
};
