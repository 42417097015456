import React, { useEffect, useState, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { Barlow, Colour } from '../../styles/style';
import SettingsIcon from '@mui/icons-material/Settings';
import Linkify from 'react-linkify';
import {
  Menu,
  MenuItem,
  IconButton,
  Paper,
  Typography,
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from '@mui/material';
import { useGetAllChatByIdQuery } from '../../api/queries';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import { GET_ALL_CHATS_ID } from '../../constants/queryKeys';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import socket from '../../api/socket/socket';
import CreateChatModal from '../../components/Modals/ChatModal';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface ChatMessage {
  senderId: number;
  content: string;
  id: number;
  sender?: {
    username?: string;
  };
  createdAt: string;
  files: string[];
  status: 'delivered' | 'read';
}

interface ChatComponentProps {
  chatId: number;
  userId: number;
  isVisible: boolean;
  onChatClick: () => void;
}

const fetchChatHistory = async (chatId: number) => {
  const { data } = await axios.get<ChatMessage[]>(`https://auslaw-chat.onrender.com/messages/chat/${chatId}`);
  return data;
};

const fetchUsername = async (userId: number) => {
  const { data } = await axios.get(`https://auslaw-chat.onrender.com/users/${userId}`);
  return data.username;
};

const linkDecorator = (href: string, text: string, key: number) => (
  <a
    href={href}
    key={key}
    style={{
      color: '#007bff',
      textDecoration: 'none',
      fontWeight: 'bold',
    }}
    target="_blank"
    rel="noopener noreferrer">
    {text}
  </a>
);

const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return new Date(dateString).toLocaleString(undefined, options);
};

const ChatComponent: React.FC<ChatComponentProps> = ({ chatId, userId, onChatClick }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [text, setText] = useState<string>('');
  const [editingMessageId, setEditingMessageId] = useState<number | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);
  const [chatName, setChatName] = useState<string>('');
  const [participantsCount, setParticipantsCount] = useState<number>(0);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const queryClient = useQueryClient();
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');

  const [isEditChats, setIsEditChats] = useState(false);
  const [isChatModalVisible, setIsChatModalVisible] = useState(false);

  const { data: chatList } = useGetAllChatByIdQuery(userId);
  const [isJoinVisible, setIsJoinVisible] = useState(true);

  const [openMessageImageModal, setOpenMessageImageModal] = useState(false);
  const [selectedMessageImage, setSelectedMessageImage] = useState(null);

  const [openFullImageModal, setOpenFullImageModal] = useState(false);
  const [selectedFullImage, setSelectedFullImage] = useState(null);

  const handleOpenFullImageModal = image => {
    setSelectedFullImage(image);
    setOpenFullImageModal(true);
  };

  const handleCloseFullImageModal = () => {
    setSelectedFullImage(null);
    setOpenFullImageModal(false);
  };

  const openImageModalHandler = imageUrl => {
    setSelectedImage(imageUrl);
    setOpenImageModal(true);
  };

  const closeImageModalHandler = () => {
    setSelectedImage(null);
    setOpenImageModal(false);
  };

  const {
    data: chatHistory,
    isLoading,
    error,
  } = useQuery({
    queryKey: ['chatHistory', chatId],
    queryFn: () => fetchChatHistory(chatId),
    staleTime: 60000,
    refetchOnWindowFocus: false,
  });

  const filterCurentChat = chatList?.filter(item => item.id == chatId);
  const isPublicChatVisible = filterCurentChat?.[0]?.type === 'public' && filterCurentChat?.[0]?.creatorId != userId;

  useEffect(() => {
    if (filterCurentChat) {
      setChatName(
        filterCurentChat[0].chatName === 'Contact'
          ? filterCurentChat[0].usersInfo.find(userInfo => userInfo.id !== userId)?.username
          : filterCurentChat[0].chatName,
      );
      setParticipantsCount(filterCurentChat[0].users.length);
    }
  }, [chatId]);

  useEffect(() => {
    if (chatHistory) {
      setMessages(chatHistory);
    }
  }, [chatHistory]);

  useEffect(() => {
    socket.emit('joinChat', chatId);

    socket.on('receiveMessage', async (message: ChatMessage) => {
      try {
        let updatedMessage = message;

        if (!updatedMessage.sender || !updatedMessage.sender.username) {
          const username = await fetchUsername(updatedMessage.senderId);
          updatedMessage = { ...message, sender: { username } };
        }

        setMessages(prevMessages => [...prevMessages, updatedMessage]);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    });

    socket.on('messageRead', ({ messageId }) => {
      setMessages(prevMessages => prevMessages.map(msg => (msg.id === messageId ? { ...msg, status: 'read' } : msg)));
    });

    socket.on('chatEdited', editedChat => {
      setChatName(editedChat.chatName);
      setParticipantsCount(editedChat.users.length);
    });

    return () => {
      socket.off('chatEdited');
      socket.off('receiveMessage');
      socket.off('messageRead');
    };
  }, [chatId]);

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current!.scrollIntoView({ behavior: 'smooth' });
      }, 300); // 100 мс затримки для завантаження даних
    }

    messages.forEach(msg => {
      if (msg.status !== 'read' && msg.senderId !== userId) {
        socket.emit('readMessage', {
          chatId,
          messageId: msg.id,
          userId,
        });
      }
    });
  }, [messages, chatId, userId, socket]);

  const sendMessage = () => {
    if (text.trim()) {
      if (editingMessageId !== null) {
        const dataEditMessage = {
          messageId: editingMessageId,
          updateMessageDto: {
            content: text,
          },
        };

        socket.emit('editMessage', dataEditMessage);
        setIsEditMessage(!isEditMessage);
        setEditingMessageId(null);
        queryClient.invalidateQueries({ queryKey: ['chatHistory', chatId] });
      } else {
        socket.emit('sendMessage', { chatId, userId, text });
      }

      setText('');
    }
  };

  const openEditChatsModal = (id: number | React.SetStateAction<undefined>) => {
    setIsEditChats(true);
    setIsChatModalVisible(true);
  };

  const closeChatModal = () => {
    setIsChatModalVisible(false);
    setIsEditChats(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>, msgId: number) => {
    setAnchorEl(event.currentTarget);
    setSelectedMessageId(msgId);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setSelectedMessageId(null);
  };

  const handleEdit = () => {
    const message = messages.find(msg => msg.id === selectedMessageId);
    setIsEditMessage(!isEditMessage);
    if (message) {
      setEditingMessageId(message.id);
      setText(message.content);
    }
    closeMenu();
  };

  const handleDelete = () => {
    if (selectedMessageId !== null) {
      socket.emit('deleteMessage', { messageId: selectedMessageId });
      setMessages(prevMessages => prevMessages.filter(msg => msg.id !== selectedMessageId));
      queryClient.invalidateQueries({ queryKey: ['chatHistory', chatId] });
      setIsDelete(!isDelete);
    }
    closeMenu();
  };

  const inputFileRef = useRef<HTMLInputElement | null>(null);

  const [anchorElAttachment, setAnchorElAttachment] = useState<null | HTMLElement>(null);
  const [imageText, setImageText] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [files, setImgForm] = useState([]);

  const openImage = async (imageUrl: string) => {
    await setSelectedImage(imageUrl);
    await setOpenImageModal(true);
    await setImageText('');
  };

  const closeImageModal = () => {
    setSelectedImage(null);
    setOpenImageModal(false);
  };

  const confirmImageModal = () => {
    setOpenImageModal(false);
    socket.emit('sendMessage', { chatId, userId, text: imageText, files });
  };

  const openAttachmentMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElAttachment(event.currentTarget);
  };

  const closeAttachmentMenu = () => {
    setAnchorElAttachment(null);
    setImgForm(null);
  };

  const openFileSelector = () => {
    if (inputFileRef.current) {
      inputFileRef.current.click();
      closeAttachmentMenu();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = function () {
        const base64String = reader.result.split(',')[1]; // Вирізаємо сам Base64 код
        const mimeType = file.type;

        if (mimeType) {
          const prefixedBase64 = `data:${mimeType};base64,${base64String}`;
          setImgForm([prefixedBase64]);
        }
      };

      // reader.onload = function (event) {
      //   const base64String = event.target.result.split(',')[1];
      //   setImgForm([base64String]);
      // };
      reader.readAsDataURL(file);
      const imageUrl = URL.createObjectURL(file);
      openImage(imageUrl);
      // const formData = new FormData();
      // formData.append('file', file);
      // setImgForm(formData);
      event.target.value = '';
    }
  };

  const checkJoinUser = filterCurentChat && filterCurentChat[0]?.users?.includes(String(userId));

  const JoinChanelPublic = () => {
    const usersCurrentChat = filterCurentChat && filterCurentChat[0].users;
    if (usersCurrentChat) {
      const chatDataEdit = {
        chatId,
        updateChatDto: {
          chatName,
          type: 'public',
          users: [...usersCurrentChat, String(userId)],
        },
      };
      setIsJoinVisible(false);

      socket.emit('editChat', chatDataEdit);
      setParticipantsCount(chatDataEdit.updateChatDto.users?.length);
      queryClient.invalidateQueries({ queryKey: [GET_ALL_CHATS_ID, userId] });
    }
  };

  useEffect(() => {
    socket.on('messageEdited', editedMessage => {
      setMessages(prevMessages =>
        prevMessages.map(message => (message.id === editedMessage.id ? { ...message, ...editedMessage } : message)),
      );
    });

    socket.on('messageDeleted', deletedMessage => {
      setMessages(prevMessages => prevMessages.filter(message => message.id !== deletedMessage.id));
    });

    return () => {
      socket.off('messageEdited');
      socket.off('messageDeleted');
    };
  }, [socket, chatId]);

  useEffect(() => {
    const handleFocus = () => setKeyboardOpen(true);
    const handleBlur = () => setKeyboardOpen(false);

    const input = inputRef.current;
    if (input) {
      input.addEventListener('focus', handleFocus);
      input.addEventListener('blur', handleBlur);
    }

    return () => {
      if (input) {
        input.removeEventListener('focus', handleFocus);
        input.removeEventListener('blur', handleBlur);
      }
    };
  }, []);

  useEffect(() => {
    if (keyboardOpen && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [keyboardOpen]);

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">Error loading chat history: {error.message}</Typography>;

  return (
    <Paper
      elevation={3}
      sx={{
        background: 'transparent',
        width: '100%',
        height: 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
      }}>
      <Box
        sx={{
          height: '20px',
          background: 'white',
          display: 'flex',
          alignItems: 'center',
          paddingBlock: '10px',
          paddingLeft: isMobile ? '60px' : '10px',
        }}>
        <Typography
          sx={{
            color: Colour.DarkBlue,
            fontFamily: Barlow.bold.fontFamily,
            fontWeight: '200',
          }}>
          {chatName}, {participantsCount} participants
        </Typography>

        <IconButton onClick={() => openEditChatsModal(chatId)} style={{ marginLeft: 'auto' }}>
          <SettingsIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          flex: 1,
          padding: '10px',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          // backgroundColor: '#f0f0f0',
        }}>
        {messages.map(msg => (
          <Paper
            key={msg.id}
            elevation={2}
            sx={{
              alignSelf: msg.senderId === userId ? 'flex-end' : 'flex-start',
              backgroundColor: msg.senderId === userId ? 'white' : '#f0f0f0',
              color: 'black',
              padding: '10px',
              borderRadius: '10px',
              marginBottom: '10px',
              maxWidth: '40%',
              position: 'relative',
            }}>
            <Typography sx={{ fontWeight: 'bold', marginTop: '6px', wordBreak: 'break-word' }}>
              {msg.sender?.username || 'Unknown'}
            </Typography>
            {msg.files.length > 0 &&
              msg.files.map(item => (
                <img
                  src={item}
                  alt="Message Image"
                  onClick={() => handleOpenFullImageModal(item)}
                  style={{
                    width: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain',
                    borderRadius: '8px',
                    marginTop: '5px',
                    cursor: 'pointer',
                  }}
                />
              ))}

            {selectedFullImage && (
              <Dialog
                open={openFullImageModal}
                onClose={handleCloseFullImageModal}
                fullWidth
                maxWidth="lg"
                sx={{
                  '.MuiDialog-paper': {
                    margin: 0,
                    width: 'max-content',
                    height: 'max-content',
                    maxHeight: '90%',
                    boxShadow: 'none',
                  },
                  '.MuiBackdrop-root': {
                    backgroundColor: 'transparent',
                  },
                }}>
                <DialogContent
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 0,
                  }}>
                  {selectedFullImage && (
                    <img
                      src={selectedFullImage}
                      alt="Selected"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100vh',
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </DialogContent>
                <DialogActions
                  sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                  }}>
                  <Button
                    onClick={handleCloseFullImageModal}
                    variant="contained"
                    sx={{
                      borderRadius: '20px',
                      padding: '5px 5px',
                      background: '#0a69a8',
                    }}>
                    Х
                  </Button>
                </DialogActions>
              </Dialog>
            )}

            {msg.content && (
              <Linkify componentDecorator={linkDecorator}>
                <Typography sx={{ marginBottom: '5px', marginTop: '5px', wordBreak: 'break-word' }}>
                  {msg.content}
                </Typography>
              </Linkify>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '24px' }}>
              <Typography sx={{ fontSize: '12px', color: '#888', textAlign: 'right' }}>
                {formatDate(msg.createdAt)}
              </Typography>
              {/* Додайте іконки для статусу прочитаних/непрочитаних повідомлень */}
              {msg.senderId === userId && msg.status === 'read' ? (
                <DoneAllRoundedIcon sx={{ fontSize: '24px', color: '#0A7BB8' }} />
              ) : (
                msg.senderId === userId && <DoneRoundedIcon sx={{ fontSize: '24px', color: 'black' }} />
              )}
            </Box>

            {msg.senderId === userId && (
              <>
                <IconButton onClick={e => openMenu(e, msg.id)} sx={{ position: 'absolute', top: '8px', right: '5px' }}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl) && selectedMessageId === msg.id}
                  onClose={closeMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  <MenuItem onClick={handleEdit}>Edit</MenuItem>
                  <MenuItem onClick={handleDelete}>Delete</MenuItem>
                </Menu>
              </>
            )}
          </Paper>
        ))}

        <div ref={messagesEndRef} />
      </Box>

      {/* меню для вибору файлу або фото */}
      <Menu
        anchorEl={anchorElAttachment}
        open={Boolean(anchorElAttachment)}
        onClose={closeAttachmentMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          },
        }}>
        <MenuItem onClick={openFileSelector} sx={{ display: 'flex', alignItems: 'center' }}>
          <ImageIcon sx={{ marginRight: '10px' }} />
          Select Photo
        </MenuItem>
        {/* <MenuItem onClick={openFileSelector} sx={{ display: 'flex', alignItems: 'center' }}> */}
        {/* <MenuItem sx={{ display: 'flex', alignItems: 'center' }}>
          <DescriptionIcon sx={{ marginRight: '10px' }} />
          Select File
        </MenuItem> */}
      </Menu>

      {/* Прихований input для вибору файлів */}
      <input
        type="file"
        accept="image/*,application/pdf,.doc,.docx"
        ref={inputFileRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />

      {/* Модалка для перегляду зображення */}
      <Dialog open={openImageModal}>
        <DialogTitle sx={{ textAlign: 'center', fontFamily: Barlow.bold.fontFamily }}>Image Preview</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            maxHeight: '80vh',
            overflowY: 'auto',
          }}>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected"
              style={{
                width: '100%',
                maxWidth: '300px', // Adjusted max width
                height: 'auto', // Maintains aspect ratio
                objectFit: 'cover',
                borderRadius: '10px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            />
          )}
          <TextField
            value={imageText}
            onChange={e => setImageText(e.target.value)}
            placeholder="Type a message..."
            variant="outlined"
            fullWidth
            sx={{
              marginTop: '10px',
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                '& fieldset': {
                  borderColor: '#ddd',
                },
                '&:hover fieldset': {
                  borderColor: 'rgb(10, 123, 192)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(10, 123, 192)',
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: '0 20px 20px' }}>
          <Button
            // onClick={confirmImageModal}
            onClick={confirmImageModal}
            color="primary"
            variant="contained"
            sx={{
              backgroundColor: 'rgb(10, 123, 192)',
              color: 'white',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'rgb(10, 100, 160)',
              },
              flexGrow: 1,
              marginRight: '10px',
            }}>
            Send
          </Button>
          <Button onClick={closeImageModal} color="secondary" variant="outlined" sx={{ borderRadius: '20px' }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Поле відправки повідомлення */}

      {!isPublicChatVisible ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            background: 'white',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
          }}>
          <IconButton onClick={openAttachmentMenu} sx={{ padding: '10px' }}>
            <AttachFileIcon />
          </IconButton>

          <TextField
            value={text}
            onChange={e => setText(e.target.value)}
            onKeyDown={handleKeyDown}
            variant="outlined"
            placeholder={isEditMessage ? 'Edit your message' : 'Type a message'}
            fullWidth
            multiline
            inputRef={inputRef}
            sx={{
              marginRight: '10px',
              '& .MuiInputBase-input': {
                cursor: 'text',
                padding: '0 10px',
                lineHeight: '1.2',
              },
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                '& fieldset': {
                  borderColor: 'transparent',
                },
                '&:hover fieldset': {
                  borderColor: '#ccc',
                },
                '&.Mui-focused fieldset': {
                  borderColor: 'rgb(10, 123, 192)',
                },
              },
            }}
          />

          <Button
            onClick={sendMessage}
            variant="contained"
            sx={{
              backgroundColor: 'rgb(10, 123, 192)',
              color: 'white',
              height: '45px',
              minWidth: '96px',
              borderRadius: '20px',
              '&:hover': {
                backgroundColor: 'rgb(10, 100, 160)',
              },
            }}>
            {isEditMessage ? 'Save' : 'Send'}
          </Button>
        </Box>
      ) : !checkJoinUser && isJoinVisible ? (
        <button
          onClick={JoinChanelPublic}
          style={{
            width: '100%',
            height: '50px',
            background: 'white',
            cursor: 'pointer',
            color: Colour.Blue,
            border: 'none',
          }}>
          JOIN CHANEL
        </button>
      ) : (
        <></>
      )}

      {isChatModalVisible && (
        <CreateChatModal
          userId={userId}
          edit={isEditChats}
          chatId={chatId}
          onChatClick={onChatClick}
          onClose={() => {
            closeChatModal();
            queryClient.invalidateQueries({ queryKey: [GET_ALL_CHATS_ID, userId] });
          }}
        />
      )}
    </Paper>
  );
};

export default ChatComponent;
