import { useMutation } from '@tanstack/react-query';
import { apiHandlers } from '../apiHandlers';

export const useAdminMutation = () => {

  const { mutate: editUserRole } = useMutation({
    mutationFn: apiHandlers.admins.editUserRole,
  });

  const {mutate: deleteUserCommunity}  =useMutation({
    mutationFn: apiHandlers.admins.deleteUserCommunity
  })


  return {
    editUserRole,
    deleteUserCommunity
  };
};
