import React from 'react';
import chatImg from "../../assets/images/chat.svg";
import { useLinkTo } from '@react-navigation/native';
import { PrimaryButton } from '../../components/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { userStateSelector } from '../../redux/Store';
import { useMediaQuery } from '@mui/material';

function ChatReviewComponent() {
  const linkTo = useLinkTo();
  const userState = useSelector(userStateSelector);
  const isMobile = useMediaQuery('(max-width:900px)');

  const { plan, parentProfileId } = userState.profile || {};
  const hasPlan = plan?.subscriptionId !== undefined || parentProfileId != null;

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      fontFamily: 'Barlow',
      color: 'white',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'center' : 'normal'
    }}>
      <div style={{
        width: isMobile ? '100%' : '50%',
        paddingLeft: isMobile ? '0' : '10px',
        height: isMobile ? 'auto' : 'calc(100vh - 64px)',
        display: 'flex',
        justifyContent: 'center'
      }}>
        <img src={chatImg} style={{
          width: isMobile ? '80%' : '100%',
          height: isMobile ? 'auto' : '100%',
          maxHeight: isMobile ? '300px' : 'none'
        }} alt="Chat illustration" />
      </div>
      <div style={{
        width: isMobile ? '100%' : '50%',
        height: isMobile ? 'auto' : 'calc(100vh - 64px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isMobile ? '20px' : '0'
      }}>
        <div style={{ padding: '20px', textAlign: 'center' }}>
          <h1 style={{ fontSize: isMobile ? '1.5em' : '2em', marginBottom: '20px' }}>Get full access to the chat and stay connected!</h1>
          <p style={{ fontSize: isMobile ? '1em' : '1.2em', lineHeight: '1.6' }}>
            Our chat platform offers countless possibilities for communication and information exchange. With a subscription, you’ll get access to:
          </p>
          <ul style={{ fontSize: isMobile ? '1em' : '1.2em', lineHeight: '1.6', textAlign: isMobile ? 'center' : 'left' }}>
            <li><strong>Private messages:</strong> Communicate one-on-one with friends or colleagues in a secure environment.</li>
            <li><strong>Public and private chats:</strong> Join open discussions or create your own private chats for a select group.</li>
            <li><strong>Create your own chats:</strong> Build communities around your interests and easily discuss any topic.</li>
            <li><strong>Instant updates:</strong> Stay informed with the latest news and events in your communities.</li>
          </ul>
          <p style={{ fontSize: isMobile ? '1em' : '1.2em', lineHeight: '1.6', fontWeight: 600 }}>
            It’s a fantastic way to stay connected and never miss important moments!
          </p>
          <p style={{ fontSize: isMobile ? '1em' : '1.2em', lineHeight: '1.6' }}>
            To unlock all these features, simply register and subscribe. This will allow you to make the most of everything our chat platform has to offer!
          </p>
        </div>
        <div style={{ width: isMobile ? '80%' : 250 }}>
          <PrimaryButton
            title="GET STARTED"
            iconRight
            icon={
              <View style={{ justifyContent: 'center' }}>
                <KeyboardArrowRightIcon sx={{ color: 'white' }} />
              </View>
            }
            onPress={() => {
              if (hasPlan) {
                linkTo({
                  screen: 'CreateListing',
                  params: {
                    id: userState.listing?.id,
                  },
                });
              } else {
                linkTo({
                  screen: 'Payment',
                  params: {
                    invite: false,
                    createListing: true,
                  },
                });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ChatReviewComponent;
