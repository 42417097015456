import { useQuery } from '@tanstack/react-query';
import { GET_EVENT_BY_CALENDAR } from '../../constants/queryKeys';
import { apiHandlers } from '../apiHandlers';

export const useGetEventByCalendarQuery = (calendarId: string, grantId: string) => {
  return useQuery({
    queryKey: [GET_EVENT_BY_CALENDAR, calendarId, grantId],
    queryFn: () => apiHandlers.connectCalendar.getEventByCalendarId(calendarId, grantId).then(data => data.data),
    enabled: !!grantId && !!calendarId,
  });
};
