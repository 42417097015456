import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  Chip,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Box,
} from '@mui/material';
import { useGetAllUsersQuery } from '../../../api/queries';
import { Colour } from '../../../styles/style';
import { useSendInviteCommunityMutation } from '../../../api/mutations';
import { queryClient } from '../../../api/queryClient';
import { GET_INVITATIONS_BY_COMMUNITY_ID } from '../../../constants/queryKeys';

function InviteModal({ inviteModalOpen, setInviteModalOpen, memberCommunity }: any) {
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const { sendInviteCommunity } = useSendInviteCommunityMutation();
  const [isSentInvitation, setIsSentInvitation] = useState({ error: null, message: '' });
  const { data: userList } = useGetAllUsersQuery();

  const storedChatId = localStorage.getItem('idChat');
  const storedUserId = localStorage.getItem('userId');

  const filteredData = userList && userList.filter(item => !memberCommunity?.includes(item.id.toString()));

  const handleUserSelect = user => {
    setSelectedUsers(prev => (prev.find(selected => selected.id === user.id) ? prev : [...prev, user]));
  };

  const handleUserDelete = userToDelete => {
    setSelectedUsers(prev => prev.filter(user => user.id !== userToDelete.id));
  };
  const handleCloseInviteModal = () => {
    if (storedChatId && storedUserId) {
      const idData = selectedUsers.map(user => user.id);
      const data = {
        communityId: +storedChatId,
        inviterId: +storedUserId,
        inviteeIds: idData,
      };
      sendInviteCommunity(
        { data },
        {
          onSuccess: () => {
            setIsSentInvitation({ error: false, message: 'Invitation sent successfully' });
            setSelectedUsers([]);
            queryClient.invalidateQueries({ queryKey: [GET_INVITATIONS_BY_COMMUNITY_ID, +storedChatId] });
            setTimeout(() => {
              setInviteModalOpen(false);
              setIsSentInvitation({ error: null, message: '' });
            }, 1000);
          },
          onError: err => {
            setIsSentInvitation({ error: true, message: 'Try again' });
          },
        },
      );
    }
  };

  return (
    <Dialog open={inviteModalOpen}>
      <DialogTitle>
        <Typography variant="h6" component="div" color="primary">
          Invite Users
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ padding: '20px', borderRadius: '8px', maxWidth: '400px' }}>
        <Typography variant="body2" color="textSecondary" style={{ marginBottom: '10px' }}>
          Select users you want to invite to the community.
        </Typography>
        {filteredData && (
          <Autocomplete
            options={filteredData.filter(user => !selectedUsers.find(selected => selected.id === user.id))}
            getOptionLabel={option => option.username}
            onChange={(event, newValue) => handleUserSelect(newValue)}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.id} onClick={() => handleUserSelect(option)}>
                <ListItemText primary={option.username} />
              </ListItem>
            )}
            renderInput={params => (
              <TextField {...params} label="Select Users" placeholder="Search users" variant="outlined" />
            )}
            style={{ marginBottom: '20px' }}
          />
        )}
        <Divider style={{ margin: '10px 0' }} />
        <Box display="flex" flexWrap="wrap" gap="8px">
          {selectedUsers.map(user => (
            <Chip
              key={user.id}
              label={user.username}
              onDelete={() => handleUserDelete(user)}
              color="primary"
              variant="outlined"
            />
          ))}
        </Box>
        <p style={{ margin: 0, textAlign: 'center', color: isSentInvitation.error ? 'red' : 'green' }}>
          {isSentInvitation.message}
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCloseInviteModal}
          style={{ marginTop: '20px', borderRadius: '6px', width: '100%', padding: '10px' }}>
          Send Invites
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setInviteModalOpen(false)}
          style={{
            marginTop: 5,
            borderRadius: '6px',
            width: '100%',
            padding: '10px',
            background: Colour.LightBlue,
            color: Colour.DarkerBlue,
          }}>
          Close
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default InviteModal;
