import { useMutation } from "@tanstack/react-query"
import { apiHandlers } from "../apiHandlers"


export const useAuthmutations = () => {
    const {mutate : loginMutate} = useMutation({
        mutationFn: apiHandlers.auth.login
    })
    const {mutate : registerMutate} = useMutation({
        mutationFn: apiHandlers.auth.register
    })


    return {
        loginMutate,
        registerMutate
    }
}